import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { CONSTANT_CONTACT, CONSTANT_INFORMATION } from "@constants";
import {
    SendEmailComponent,
    ResetPassComponent,
    LoginLogo,
    AuthenticationComponent,
} from "@components";
import { IAuthenticationCode, IForgotPassEmail, IResetPassword } from "@interfaces";
import { forgotPassEmailSchema, resetPassSchema } from "@validations";
import { AppDispatch, loadAccountDetail, selectAccount, toggleLoading, useAppSelector, useIsLoggedIn } from "@redux";
import { authApi } from "@api";
import { SuccessToast } from "src/Components/toasts/SuccessToast";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { useNavigate } from "react-router-dom";
import { InfoToast } from "src/Components/toasts/InfoToast";
import { checkNeedsRealEmail, checkShouldVerifyEmail } from "src/Utilities/routeHelper";

export function ForgotPassword() {
    const SEND_EMAIL_SCREEN = "send_email_screen";
    const RESET_PASSWORD_SCREEN = "reset_password_screen";
    const AUTHENTICATION_CODE_SCREEN = "authentication_code_screen";
    const isLoggedIn = useIsLoggedIn();
    const account = useAppSelector(selectAccount);
    const [needsRealEmail] = useState(checkNeedsRealEmail(isLoggedIn, account.account));
    const [shouldVerifyEmail] = useState(checkShouldVerifyEmail(isLoggedIn, account.account));
    const [screen, setScreen] = useState(
        // instead of forgot password behave like email verification
        needsRealEmail ? SEND_EMAIL_SCREEN : shouldVerifyEmail ? AUTHENTICATION_CODE_SCREEN : SEND_EMAIL_SCREEN
    );
    const dispatch = useDispatch<AppDispatch>();
    const constantText = `${CONSTANT_INFORMATION}`;
    const constantContact = `Contact : ${CONSTANT_CONTACT}`;
    const initialEmail = shouldVerifyEmail ? account.account?.email ?? '' : '';

    const initialValuesEmail: IForgotPassEmail = { email: initialEmail };
    const initialValuesCode: IAuthenticationCode = { otp: "" };
    const initialValuesPassword: IResetPassword = { password: "", confirmPassword: "" };
    const [email, setEmail] = useState(initialEmail);
    const [otp, setOtp] = useState(initialValuesCode.otp);
    const navigate = useNavigate();

    useEffect(() => {
        // TODO: fix showing toast twice when redirecting from login
        if (shouldVerifyEmail === true) {
            InfoToast({ Message: 'Verify your email'});
        }
    }, []);

    return (
        <>
            <LoginLogo src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" />

            {screen === SEND_EMAIL_SCREEN && (
                <Formik
                    initialValues={initialValuesEmail}
                    validationSchema={forgotPassEmailSchema}
                    onSubmit={async (values) => {
                        const emailData = { email: values.email };
                        setEmail(values.email);
                        try {
                            dispatch(toggleLoading(true));
                            if (needsRealEmail) {
                                const setRealEmailRes = await authApi.setRealEmail({new_email: values.email});
                                SuccessToast({ Message: setRealEmailRes.data.message });
                            } else {
                                const sendForgotPassEmailRes = await authApi.forgotPassEmail(emailData);
                                SuccessToast({ Message: sendForgotPassEmailRes.data.message });
                            }
                            setScreen(AUTHENTICATION_CODE_SCREEN);
                        } catch (err: any) {
                            ErrorToast({ Message: err.response.data.message });
                        } finally {
                            dispatch(toggleLoading(false));
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <SendEmailComponent
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            constantText={constantText}
                            constantContact={constantContact}
                        />
                    )}
                </Formik>
            )}

            {screen === AUTHENTICATION_CODE_SCREEN && (
                <Formik
                    initialValues={initialValuesCode}
                    onSubmit={async (values, { setErrors }) => {
                        const codeData = { otp: values.otp, email: email };
                        setOtp(values.otp);
                        try {
                            dispatch(toggleLoading(true));
                            const res = await (needsRealEmail ? authApi.verifyRealEmail(codeData) : authApi.forgotPassCode(codeData));
                            if (res.data.code === 200) {
                                await dispatch(loadAccountDetail());
                                SuccessToast({ Message: res.data.message });
                                if (shouldVerifyEmail) {
                                    // user verified email so just reload to reflect changes
                                    window.location.href = '/';
                                    return;
                                }
                                setScreen(RESET_PASSWORD_SCREEN);
                            }
                        } catch (err: any) {
                            setErrors({ otp: err.response.data.title ?? err.response.data.data.otp?.[0] });
                        } finally {
                            dispatch(toggleLoading(false));
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting,errors,
                    touched,}) => (
                        <AuthenticationComponent
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            constantText={constantText}
                            constantContact={constantContact}
                            enteredEmail={email}
                            errors={errors}
                            touched={touched}
                        />
                    )}
                </Formik>
            )}

            {screen === RESET_PASSWORD_SCREEN && (
                <Formik
                    initialValues={initialValuesPassword}
                    validationSchema={resetPassSchema}
                    onSubmit={async (values) => {
                        const passwordData = {
                            new_password: values.password,
                            otp: otp,
                            email: email,
                        };
                        try {
                            dispatch(toggleLoading(true));
                            const res = await authApi.forgotPass(passwordData);
                            if (res.data.code === 200) {
                                SuccessToast({ Message: res.data.message });
                                navigate("/login");
                            }
                        } catch (err: any) {
                            ErrorToast({ Message: err.response.data.message });
                        } finally {
                            dispatch(toggleLoading(false));
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <ResetPassComponent
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            constantText={constantText}
                            constantContact={constantContact}
                        />
                    )}
                </Formik>
            )}
        </>
    );
}
