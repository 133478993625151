import { phpHelper } from "src/Utilities/phpHelper";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPaymentMethod, IListBookings, IBookingDetail, IBookingCancelRequest } from "@interfaces";
import { bookingApi } from "@api";
import { RootState } from ".";

interface IInit {
    paymentMethod: IPaymentMethod | null;
    listBooking: IListBookings[];
    isLoading: boolean;
    bookingDetail: IBookingDetail | null;
    listBCR: IBookingCancelRequest[];
}

const initialState: IInit = {
    paymentMethod: null,
    listBooking: [],
    isLoading: true,
    bookingDetail: null,
    listBCR: [],
};

export const getPaymentMethod = createAsyncThunk<IPaymentMethod, void>(
    "booking/getPaymentMethod",
    async (action, { rejectWithValue }) => {
        try {
            const res = await bookingApi.getPaymentMethod();

            const paymentMethod = res.data.paymentMethods.find((item: IPaymentMethod) => {
                if (process.env.REACT_APP_API_URL?.includes("test")) {
                    return item.code === "credit_card_pay_stripetest_aagt";
                } else {
                    return item.code === "credit_card_pay_stripe_aagt";
                }
            });
            return paymentMethod as IPaymentMethod;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getListBooking = createAsyncThunk(
    "listBooking/GET",
    async (action, { rejectWithValue }) => {
        try {
            const res = await bookingApi.getListBooking();
            return res.data.data.orders;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getListBookingCancelRequest = createAsyncThunk(
    "listBCR/GET",
    async (action, { rejectWithValue }) => {
        try {
            const res = await phpHelper.getCancelRequests();
            //@ts-ignore
            return res as IBookingCancelRequest[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getBookingDetail = createAsyncThunk<IBookingDetail, string>(
    "bookingDetail/GET",
    async (action: string | number, { rejectWithValue }) => {
        try {
            const res = await bookingApi.getBookingDetail(action);

            const bookingDetail = res.data as IBookingDetail;

            //@ts-ignore
            const cancelRes = (await phpHelper.getCancelRequest(
                bookingDetail.bookingReference || "none",
            )) as IBookingCancelRequest[];

            const products = bookingDetail.products.map((product) => {
                const cancelRequest = cancelRes.find(
                    (cancel) => cancel.voucherNumber === product.voucherNumber,
                );

                return {
                    ...product,
                    cancelRequest: !!cancelRequest,
                    cancelQuantity: cancelRequest?.quantity || 0,
                };
            });

            return { ...bookingDetail, products };
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

const booking = createSlice({
    name: "booking",
    initialState,
    reducers: {
        setListBooking: (state, action) => {
            state.listBooking = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload.paymentMethod;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getPaymentMethod.fulfilled,
            (state, action: PayloadAction<IPaymentMethod>) => {
                state.paymentMethod = action.payload;
            },
        );
        builder.addCase(getListBooking.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListBooking.rejected, (state) => {
            state.listBooking = [];
            state.isLoading = false;
        });
        builder.addCase(getListBooking.fulfilled, (state, action: PayloadAction<any>) => {
            state.listBooking = action.payload;
            console.log("ggwp",state.listBooking);
            state.isLoading = false;
        });
        builder.addCase(
            getListBookingCancelRequest.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.listBCR = action.payload;
            },
        );
        builder.addCase(getListBookingCancelRequest.rejected, (state) => {
            state.listBCR = [];
        });
        builder.addCase(getBookingDetail.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getBookingDetail.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(
            getBookingDetail.fulfilled,
            (state, action: PayloadAction<IBookingDetail>) => {
                state.isLoading = false;
                state.bookingDetail = action.payload;
            },
        );
    },
});

export const { setListBooking, setPaymentMethod } = booking.actions;

export const selectBooking = (state: RootState) => state.booking;

export default booking.reducer;
// export const {} = booking.actions;
