import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    ICart,
    IFareCart,
    ICustomerWithCart,
    IChangePickup,
    IAddCart,
    ISetCartBookingData,
    ICustomerDetail,
    ISetCustomerData,
} from "@interfaces";
import { CartConstants } from "@constants";
import { RootState } from ".";
import { cartApi } from "@api";
import _ from "lodash";
import { calculatePricingInfo } from "src/Utilities/cartHelper";
import { LoadingStage } from "src/Interfaces/shared";

const recalculateTotals = (carts: IFareCart[]) => {
    const totalRRP = carts.reduce(
        (accumulator, cartItem) =>
            accumulator +
            cartItem.availability?.productPricingData?.RRP * cartItem.booking_quantity!,
        0,
    );
    const totalNet = carts.reduce(
        (accumulator, cartItem) =>
            accumulator +
            (cartItem.availability?.productPricingData.NetRate || 0) * cartItem.booking_quantity!,
        0,
    );
    const commission = totalRRP - totalNet;
    const commissionPercent = totalRRP ? (commission / totalRRP) * 100 : 0;

    return {
        totalRRP,
        totalNet,
        commission,
        commissionPercent,
        totalPrice: totalRRP, // Set totalPrice to totalRRP
    };
};

// Function to update totalPrice
const setTotalPrice = (state: ICart) => {
    const totals = recalculateTotals(state.carts);
    state.totalRRP = totals.totalRRP;
    state.totalNet = totals.totalNet;
    state.commission = totals.commission;
    state.commissionPercent = totals.commissionPercent;
    state.totalPrice = totals.totalPrice;
};

const initialState: ICart = {
    carts: [],
    cartItemsLoadingStage: LoadingStage.NOT_INITIALIZED,
    totalRRP: 0,
    totalNet: 0,
    totalPrice: 0,
    commission: 0,
    commissionPercent: 0,
    customers: [],
    customersLoadingStage: LoadingStage.NOT_INITIALIZED,
    isShare: false,
    optionalFieldValidated: true,
    canCheckout: false,
    redirectUrl: "",
    bookingReference: "",
    step: CartConstants.CART,
    currentStep: CartConstants.CART,
};

export const INCREASE_QTY = "increase";
export const DECREASE_QTY = "decrease";

export const fetchCart = createAsyncThunk<IFareCart[]>(
    "cart/fetchCart",
    async (_, { rejectWithValue }) => {
        try {
            const response = await cartApi.getCart();
            return response.data.data as IFareCart[];
        } catch (error) {
            console.error("Failed to fetch the cart:", error);
            return rejectWithValue("Unable to fetch cart data.");
        }
    },
);

export const addToCartAndFetch = createAsyncThunk<IFareCart[], IAddCart>(
    "cart/addToCartAndFetch",
    async (newCartItem, { rejectWithValue }) => {
        try {
            await cartApi.addCart(newCartItem);
            const response = await cartApi.getCart();
            return response.data.data as IFareCart[];
        } catch (error) {
            console.error("Failed to add item to cart or fetch updated cart:", error);
            return rejectWithValue("Unable to add item to cart and fetch updated cart data.");
        }
    },
);

export const fetchCustomer = createAsyncThunk<ICustomerWithCart[]>(
    "customer/fetchCustomer",
    async (_, { rejectWithValue }) => {
        try {
            const response = await cartApi.getCustomer();
            return response.data.data as ICustomerWithCart[];
        } catch (error) {
            console.error("Failed to fetch customers:", error);
            return rejectWithValue("Unable to fetch customer data.");
        }
    },
);

export const updateAndFetchCustomer = createAsyncThunk<ICustomerDetail[], ISetCustomerData[]>(
    "customer/updateAndFetchCustomer",
    async (newCartItem, { rejectWithValue }) => {
        try {
            await cartApi.updateCustomer(newCartItem);
            const response = await cartApi.getCustomer();
            return response.data.data as ICustomerWithCart[];
        } catch (error) {
            console.error("Failed to update customer or fetch customer data:", error);
            return rejectWithValue("Unable to update customer and fetch updated customer data.");
        }
    },
);

export const submitCartDataAndFetch = createAsyncThunk<any, ISetCartBookingData[]>(
    "cart/submitCartDataAndFetch",
    async (itemsBookingData, { getState, rejectWithValue }) => {
        const state = getState() as RootState;

        try {
            const cartItemIds = itemsBookingData.map(i => i.itemId);
            const responses = await Promise.allSettled(
                itemsBookingData.map(async (itemBookingData) => {

                    const payload = {
                        quantity: 1,
                        bookingData: itemBookingData.bookingData,
                    };
                    const response = await cartApi.saveCart(itemBookingData.itemId, payload);
                    return response.data;
                }),
            );

            const hasFailed = responses.some(r => r.status === 'rejected');
            if (hasFailed) {
                throw new Error('Failed to update cart items');
            }

            const deletedCartItemIds = state.cart.carts.filter(c => !_.includes(cartItemIds, c.id)).map(c => c.id);
            const deleteCartItemsResponse = await Promise.allSettled(
                deletedCartItemIds.map(cartItemId => cartApi.removeCart(cartItemId))
            );
            const hasDeleteFailed = deleteCartItemsResponse.some(r => r.status === 'rejected');
            if (hasDeleteFailed) {
                throw new Error('Failed to delete cart items');
            }
            const response = await cartApi.getCart();
            return response.data.data as IFareCart[];
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

export const removeCartAndFetch = createAsyncThunk<IFareCart[], string | number>(
    "cart/removeCartAndFetch",
    async (cartItemId, { rejectWithValue }) => {
        try {
            // Remove item from the cart via API
            await cartApi.removeCart(cartItemId);

            // Fetch updated cart data
            const response = await cartApi.getCart();
            return response.data.data as IFareCart[];
        } catch (error) {
            console.error("Failed to remove item from cart or fetch updated cart:", error);
            return rejectWithValue("Unable to remove item from cart and fetch updated cart data.");
        }
    },
);

const cart = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        resetCart: () => {
            return initialState;
        },
        checkPayment: (state) => {
            state.canCheckout = initialState.canCheckout;
            // WHAT: cart exist and lead customer is field
            if (state.carts.length > 0 && state.customers[0]?.first_name !== "") {
                state.canCheckout = true;
                state.carts.forEach((cart) => {
                    // WHAT: not enough customer fer cart
                    if (cart.quantity > cart.qtyCustomer) {
                        state.canCheckout = false;
                    }
                });
            }
        },
        checkOptionalField: (state) => {
            state.optionalFieldValidated = true;
            // WHAT: check all optional field is validated
            state.carts.map((cart) => {
                cart.booking_details?.optionalFields.map((field) => {
                    // WHAT: if one mandatory field is empty or not valid the validation is false
                    if (field.type === "number") {
                        if (field.mandatory && (!field.value || field.value === "0")) {
                            state.optionalFieldValidated = false;
                        }
                    } else {
                        if (field.mandatory && (!field.value || field.value === "")) {
                            state.optionalFieldValidated = false;
                        }
                    }
                });
                if (
                    cart.pickupLocations &&
                    cart.pickupLocations?.length > 0 &&
                    !cart.pickupLocation
                ) {
                    state.optionalFieldValidated = false;
                }
            });
        },
        changePickupLocation: (state, action: PayloadAction<IChangePickup>) => {
            // WHAT: change value
            const index = state.carts.findIndex((cart) => {
                return cart.id === action.payload.itemId;
            });
            if (index >= 0) {
                state.carts[index].booking_details!.pickupLocation = action.payload.pickupLocation;
            }

            state.optionalFieldValidated = true;
            // WHAT: check all optional field is validated
            state.carts.map((cart) => {
                cart.booking_details?.optionalFields.map((field) => {
                    // WHAT: if one mandatory field is empty or not valid the validation is false
                    if (field.type === "number") {
                        if (field.mandatory && (!field.value || field.value === "0")) {
                            state.optionalFieldValidated = false;
                        }
                    } else {
                        if (field.mandatory && (!field.value || field.value === "")) {
                            state.optionalFieldValidated = false;
                        }
                    }
                });
                if (
                    cart.booking_details?.pickupLocations &&
                    cart.booking_details?.pickupLocations.length > 0 &&
                    !cart.booking_details.pickupLocation
                ) {
                    state.optionalFieldValidated = false;
                }
            });
        },
        addToCart: (state) => {
            const pricingInfo = calculatePricingInfo(state.carts);
            state = {
                ...state,
                ...pricingInfo,
            };
        },
        setTotalPriceCart: (state, action: PayloadAction<number>) => {
            const totalPrice = action.payload;

            // If totalPrice is less than or equal to totalNet, reset commission
            if (totalPrice <= state.totalNet) {
                state.commissionPercent = 0;
                state.commission = 0;
                state.totalPrice = totalPrice;
            } else {
                // Calculate commission if totalPrice is greater than totalNet
                state.commission = totalPrice - state.totalNet;
                state.commissionPercent = (state.commission / state.totalRRP) * 100;

                // Ensure commissionPercent does not go below 0
                state.commissionPercent = Math.max(state.commissionPercent, 0);

                // Update totalPrice
                state.totalPrice = totalPrice;
            }
        },
        removeCustomer: (state, action: PayloadAction<{ customerId: string }>) => {
            const customerIndex = state.customers.findIndex(
                (customer) => customer.id === action.payload.customerId,
            );
            if (customerIndex !== -1) {
                state.customers.splice(customerIndex, 1);
            }
        },
        replaceCart: (state, action: PayloadAction<ICart>) => {
            return {
                ...action.payload,
                // What : reset isShare and redirectUrl to clear old payment if have
                isShare: initialState.isShare,
                redirectUrl: initialState.redirectUrl,
            };
        },
        saveAnswersCartItem: (state, action) => {
            // WHAT: change value
            const index = state.carts.findIndex((cart) => {
                return cart.id === action.payload.itemId;
            });
            if (index >= 0) {
                const indexFiled = state.carts[index].booking_details?.optionalFields.findIndex(
                    (optionField) => {
                        return optionField.optionalFieldId === action.payload.optionalFieldId;
                    },
                );
                if (indexFiled! >= 0) {
                    //save new value
                    state.carts[index].booking_details!.optionalFields[indexFiled!].value =
                        action.payload.value;
                }
            }
            state.optionalFieldValidated = true;
            // WHAT: check all optional field is validated
            state.carts.map((cart) => {
                cart.booking_details?.optionalFields.map((field) => {
                    // WHAT: if one mandatory field is empty or not valid the validation is false
                    if (field.type === "number") {
                        if (field.mandatory && (!field.value || field.value === "0")) {
                            state.optionalFieldValidated = false;
                        }
                    } else {
                        if (field.mandatory && (!field.value || field.value === "")) {
                            state.optionalFieldValidated = false;
                        }
                    }
                });
                if (
                    cart.booking_details?.pickupLocations &&
                    cart.booking_details?.pickupLocations.length > 0 &&
                    !cart.booking_details.pickupLocation
                ) {
                    state.optionalFieldValidated = false;
                }
            });
        },
        share: (
            state,
            action: PayloadAction<{
                isShare: boolean;
                redirectUrl: string;
            }>,
        ) => {
            state.isShare = action.payload.isShare;
            state.redirectUrl = action.payload.redirectUrl;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setBookingReference: (state, action: PayloadAction<string>) => {
            state.bookingReference = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCart.pending, (state) => {
                state.cartItemsLoadingStage = LoadingStage.IS_INITIALIZING;
            })
            .addCase(fetchCart.fulfilled, (state, action) => {
                state.carts = action.payload;
                state.cartItemsLoadingStage = LoadingStage.INITIALIZE_COMPLETE;
                setTotalPrice(state);
            })
            .addCase(fetchCart.rejected, (state, action) => {
                console.error("Failed to fetch cart:", action.payload);
            })
            .addCase(fetchCustomer.fulfilled, (state, action) => {
                state.customers = action.payload;
            })
            .addCase(fetchCustomer.rejected, (state, action) => {
                console.error("Failed to update customer", action.payload);
            })
            .addCase(updateAndFetchCustomer.fulfilled, (state, action) => {
                state.customers = action.payload;
            })
            .addCase(updateAndFetchCustomer.rejected, (state, action) => {
                console.error("Failed to update customer", action.payload);
            })
            .addCase(addToCartAndFetch.fulfilled, (state, action) => {
                state.carts = action.payload;
                setTotalPrice(state);
            })
            .addCase(addToCartAndFetch.rejected, (state, action) => {
                console.error("Failed to update cart:", action.payload);
            })
            .addCase(submitCartDataAndFetch.rejected, (state, action) => {
                console.error('Failed to submit cart data and fetch', action.payload);
            })
            .addCase(submitCartDataAndFetch.fulfilled, (state, action) => {
                state.carts = action.payload;
                setTotalPrice(state);
            })
            .addCase(removeCartAndFetch.fulfilled, (state, action) => {
                const updatedCart = action.payload;

                // Update the local state based on the fetched data
                state.carts = updatedCart;

                // Recalculate totals
                state.totalRRP = updatedCart.reduce(
                    (accumulator, cartItem) =>
                        accumulator +
                        cartItem.availability?.productPricingData.RRP * cartItem.booking_quantity,
                    0,
                );
                state.totalNet = updatedCart.reduce(
                    (accumulator, cartItem) =>
                        accumulator +
                        (cartItem.availability?.productPricingData.NetRate || 0) *
                            cartItem.booking_quantity!,
                    0,
                );
                state.commission = state.totalRRP - state.totalNet;
                state.commissionPercent =
                    state.totalRRP !== 0 ? (state.commission / state.totalRRP) * 100 : 0;
                state.totalPrice = state.totalRRP;
            })
            // Handle failure case when removing cart item
            .addCase(removeCartAndFetch.rejected, (state, action) => {
                console.error("Failed to remove item from cart:", action.payload);
                // Handle failure (e.g., show an error message)
            });
    },
});

export const selectCart = (state: RootState) => state.cart;

export const {
    resetCart,
    addToCart,
    removeCustomer,
    replaceCart,
    saveAnswersCartItem,
    setTotalPriceCart,
    checkOptionalField,
    checkPayment,
    share,
    setStep,
    setCurrentStep,
    setBookingReference,
    changePickupLocation,
} = cart.actions;

export default cart.reducer;
