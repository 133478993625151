import { toast } from "react-toastify";
import styles from "../../style.module.css";
import { ToastCloseButton } from "./ToastCloseButton";

interface ToastMessage {
  Message: string;
}

export const InfoToast = ({ Message }: ToastMessage) => {
  toast.info(Message, {
    className: styles.toastSuccess,
    closeButton: ToastCloseButton,
    icon: () => <></>,
  });
};
