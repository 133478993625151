export const IconSetupLoader = () => (
    <svg width="150" height="150" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="arcGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="90%" stop-color="#28B7C3" />
                <stop offset="100%" stop-color="#436BB2" />
            </linearGradient>
        </defs>

        <circle cx="75" cy="75" r="65" fill="none" stroke="#7DD4DB50" strokeWidth="3" />

        <circle
            cx="75"
            cy="75"
            r="65"
            fill="none"
            stroke="url(#arcGradient)"
            strokeWidth="7"
            strokeDasharray="50 400"
            strokeLinecap="round"
            transform="rotate(-90 75 75)"
        >
            <animateTransform
                attributeType="XML"
                attributeName="transform"
                type="rotate"
                from="0 75 75"
                to="360 75 75"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </circle>

        <image
            href="/images/setupProfile.svg"
            x="40"
            y="40"
            width="70"
            height="70"
            clip-path="url(#circleClip)"
        />
        <defs>
            <clipPath id="circleClip">
                <circle cx="75" cy="75" r="35" />
            </clipPath>
        </defs>
    </svg>
);
