import { IAccount } from "@interfaces";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStage } from "src/Interfaces/shared";
import { RootState } from ".";
import { accountApi } from "src/Api/accountApi";
import { lsAccount, lsEmail } from "@utilities";
import _ from "lodash";

export const initializeAccountDetail = createAsyncThunk<IAccount, void>(
    "account/initializeAccountDetail",
    async (_, { rejectWithValue }) => {
        try {
            const res = await accountApi.userProfile();
            return res.data.data as IAccount;
        } catch (err: any) {
            const errorMessage = "Failed to initialize account detail";
            console.error(errorMessage, err);
            return rejectWithValue(errorMessage);
        }
    },
);

export const loadAccountDetail = createAsyncThunk<IAccount, void>(
    "account/loadAccountDetail",
    async (_, { rejectWithValue }) => {
        try {
            const res = await accountApi.userProfile();
            return res.data.data as IAccount;
        } catch (err: any) {
            const errorMessage = "Failed to load account detail";
            console.error(errorMessage, err);
            return rejectWithValue(errorMessage);
        }
    },
);

interface IAccountState {
    account?: IAccount | null;
    loadingStage: LoadingStage;
    isLoading: boolean;
    error?: string | null;
}

const rawAccount = lsAccount.getItem();
const localAccount: IAccount | null =
    _.isNil(rawAccount) || _.isEmpty(rawAccount) ? null : (rawAccount as IAccount);

const initialState: IAccountState = {
    account: localAccount,
    loadingStage: _.isNil(localAccount)
        ? LoadingStage.NOT_INITIALIZED
        : LoadingStage.INITIALIZE_COMPLETE,
    isLoading: true,
};

const accountSlice = createSlice({
    name: "account",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(initializeAccountDetail.pending, (state) => {
                state.loadingStage = LoadingStage.IS_INITIALIZING;
                state.isLoading = true;
            })
            .addCase(initializeAccountDetail.rejected, (state, action: PayloadAction<any>) => {
                state.loadingStage = LoadingStage.INITIALIZE_FAILURE;
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(
                initializeAccountDetail.fulfilled,
                (state, action: PayloadAction<IAccount>) => {
                    state.loadingStage = LoadingStage.INITIALIZE_COMPLETE;
                    state.isLoading = false;
                    state.account = action.payload;
                    lsAccount.setItem(state.account);
                    lsEmail.setItem(state.account.email);
                },
            )
            .addCase(loadAccountDetail.pending, (state) => {
                state.loadingStage = LoadingStage.IS_LOADING;
                state.isLoading = true;
            })
            .addCase(loadAccountDetail.rejected, (state, action: PayloadAction<any>) => {
                state.loadingStage = LoadingStage.LOADING_FAILURE;
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(loadAccountDetail.fulfilled, (state, action: PayloadAction<IAccount>) => {
                state.loadingStage = LoadingStage.LOADING_COMPLETE;
                state.isLoading = false;
                state.account = action.payload;
                lsAccount.setItem(state.account);
                lsEmail.setItem(state.account.email);
            });
    },
});

export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;
