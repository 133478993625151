import styled from "styled-components";
import moment from "moment";

import { StyledBorderButton } from "@components";
import { ICheckAvail } from "@interfaces";
import { selectStatusApp, selectTour, useAppSelector } from "@redux";

interface IProps {
    data: ICheckAvail;
    onShowPopup: () => void;
}

export const FareOptionComponent = (props: IProps) => {
    const { data, onShowPopup } = props;
    const levy = useAppSelector(selectTour).tourFare.levy;
    const statusApp = useAppSelector(selectStatusApp).status;

    const handleClick = () => {
        onShowPopup();
    };

    return data.NumAvailable > 0 ? (
        <Container>
            <div className="header">
                <div className="date">Date: {moment(data.BookingDate).format("DD/MM/YYYY")}</div>
                <ButtonSelect
                    onClick={handleClick}
                    // disabled={+data.NumAvailable <= 0}
                    // className={+data.NumAvailable <= 0 ? "disabled" : ""}
                >
                    Select
                </ButtonSelect>
            </div>
            <div className="line bgc">
                <span className="field">Avail</span>
                <span className="value">{data.NumAvailable}</span>
            </div>
            <div className="line">
                <span className="field">RRP</span>
                <span className="value">${Number(data.productPricingData.RRP).toFixed(2)}</span>
            </div>
            {data.productPricingData.NetRate && statusApp && (
                <div className="line bgc">
                    <span className="field">Net</span>
                    <span className="value net">
                        ${Number(data.productPricingData.NetRate).toFixed(2)}
                    </span>
                </div>
            )}
            {levy && statusApp && (
                <div className="line">
                    <span className="field">Levy</span>
                    <span className="value">${Number(levy).toFixed(2)}</span>
                </div>
            )}
        </Container>
    ) : (
        <></>
    );
};

const Container = styled.div`
    background-color: #fff;
    padding: 12px 16px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 13px;
    }

    .line {
        padding: 7px 18px;
        display: flex;
        justify-content: space-between;

        .value {
            font-weight: 400;

            &.net {
                color: ${(p) => p.theme.colors.main};
            }
        }

        &.bgc {
            background-color: #f8f8f8;
        }
    }
`;

const ButtonSelect = styled(StyledBorderButton)`
    width: 80px;
    height: 36px;
    margin-top: 0;

    &.disabled {
        border-color: ${(p) => p.theme.colors.gray_3};
        color: ${(p) => p.theme.colors.gray_3};
    }
`;
