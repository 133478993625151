import { useState, memo, ChangeEvent } from "react";
import { InputBase, TextField } from "@mui/material";
import { StyledError } from "@components";
import { IOptionalField, OptionalField } from "@interfaces";
import Dropdown from "./CustomDropdown"; // Importing CustomDropdown component
import { useDispatch } from "react-redux";
import { AppDispatch, saveAnswersCartItem } from "@redux";
import { FormikHandlers, FieldProps, useField, useFormikContext } from "formik";

type SetOptionalValue = (n: string | Date) => void;
type SetOptionalError = (e: string) => void;

interface IPropsCartItem {
    optionalField: OptionalField;
    itemId: string | number | null;
}

interface ISwitchInputComponent {
    optionalField: OptionalField;
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeDate: (date: Date | null) => void;
    onChangeSelect: (value: string) => void; // Now expects a value directly, not the event
}

export const SwitchInputComponent = memo((props: ISwitchInputComponent) => {
    const { optionalField, onChange, onChangeSelect } = props;

    switch (optionalField.type) {
        case "text":
            return (
                <TextField
                    onChange={onChange}
                    value={optionalField.value || ""}
                    fullWidth
                    sx={{ fontWeight: "bold" }}
                />
            );
        case "select":
            return (
                <Dropdown
                    options={optionalField.options.map((option) => ({
                        label: option,
                        value: option,
                    }))}
                    value={optionalField.value || optionalField.options[0]}
                    onChange={onChangeSelect} // Now passing the value directly
                />
            );
        case "number":
            return (
                <InputBase
                    onChange={onChange}
                    type="number"
                    inputProps={{ min: 0 }}
                    value={optionalField.value || 0}
                />
            );
        default:
            return null;
    }
});

interface INewPropsCartItem {
    optionalField: OptionalField;
    setValue: SetOptionalValue;
    setError: SetOptionalError;
}

interface INewSwitchInputComponent {
    optionalField: OptionalField;
    onChange: FormikHandlers["handleChange"];
    onChangeDate: (date: Date | null) => void;
    onChangeSelect: (value: string) => void; // Now expects a value directly, not the event
}

export const NewSwitchInputComponent = memo((props: INewSwitchInputComponent) => {
    const { optionalField, onChange, onChangeSelect } = props;

    switch (optionalField.type) {
        case "text":
            return (
                <TextField
                    onChange={onChange}
                    value={optionalField.value || ""}
                    fullWidth
                    sx={{ fontWeight: "bold" }}
                />
            );
        case "select":
            return (
                <Dropdown
                    options={optionalField.options.map((option) => ({
                        label: option,
                        value: option,
                    }))}
                    value={optionalField.value || optionalField.options[0]}
                    onChange={onChangeSelect} // Now passing the value directly
                />
            );
        case "number":
            return (
                <InputBase
                    onChange={onChange}
                    type="number"
                    inputProps={{ min: 0 }}
                    value={optionalField.value || 0}
                />
            );
        default:
            return null;
    }
});

interface IBookingDataFieldProps extends FieldProps {
    optionalField: IOptionalField;
}

export const BookingDataField = (props: IBookingDataFieldProps) => {
    const { optionalField, ...rest } = props;
    const [field] = useField(rest.field.name); // Get the field from Formik
    const { setFieldValue } = useFormikContext(); // Get Formik's setFieldValue method

    switch (optionalField.type) {
        case "text":
            return (
                <TextField
                    {...rest.field}
                    fullWidth
                    sx={{ fontWeight: "bold" }}
                    value={rest.field.value || ""}
                />
            );

        case "select":
            return (
                <Dropdown
                    options={optionalField.options.map((option) => ({
                        label: option,
                        value: option,
                    }))}
                    value={field.value || optionalField.options[0]}
                    onChange={(value) => setFieldValue(rest.field.name, value)} // Update Formik's value using setFieldValue
                />
            );

        case "number":
            return (
                <InputBase
                    {...rest.field}
                    type="number"
                    inputProps={{ min: 0 }}
                />
            );
        default:
            return null;
    }
};

export const NewOptionalFieldModule = memo((props: INewPropsCartItem) => {
    const { optionalField, setValue } = props;
    const [error, setError] = useState<string>("");

    // Handle change for text and number fields
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const type = e.target.type;
        if (!value && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        if (type === "number") {
            if (+value === -1) {
                setValue("0");
            } else if (+value >= 0) {
                setValue(value);
            }
        } else {
            setValue(value);
        }
    };

    // Handle select changes (now accepts value directly)
    const handleChangeSelect = (value: string) => {
        if (!value && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        setValue(value);
    };

    // Handle date changes
    const handleDateChange = (date: Date | null) => {
        if (!date && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        setValue(date!);
    };

    return (
        <>
            <NewSwitchInputComponent
                optionalField={optionalField}
                onChange={handleChange}
                onChangeDate={handleDateChange}
                onChangeSelect={handleChangeSelect} // Correctly passing the handler expecting value directly
            />
            <StyledError>{error}</StyledError>
        </>
    );
});

export const OptionalFieldModule = memo((props: IPropsCartItem) => {
    const { optionalField, itemId } = props;
    const dispatch = useDispatch<AppDispatch>();
    const [error, setError] = useState<string>("");

    // Handle change for text and number fields
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const type = e.target.type;
        if (!value && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        if (type === "number") {
            if (+value === -1) {
                dispatch(
                    saveAnswersCartItem({
                        itemId,
                        optionalFieldId: optionalField.optionalFieldId,
                        value: "0",
                    }),
                );
            } else if (+value >= 0) {
                dispatch(
                    saveAnswersCartItem({
                        itemId,
                        optionalFieldId: optionalField.optionalFieldId,
                        value,
                    }),
                );
            }
        } else {
            dispatch(
                saveAnswersCartItem({
                    itemId,
                    optionalFieldId: optionalField.optionalFieldId,
                    value,
                }),
            );
        }
    };

    // Handle select changes (now accepts value directly)
    const handleChangeSelect = (value: string) => {
        if (!value && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        dispatch(
            saveAnswersCartItem({
                itemId,
                optionalFieldId: optionalField.optionalFieldId,
                value,
            }),
        );
    };

    // Handle date changes
    const handleDateChange = (date: Date | null) => {
        if (!date && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        dispatch(
            saveAnswersCartItem({
                itemId,
                optionalFieldId: optionalField.optionalFieldId,
                value: date,
            }),
        );
    };

    return (
        <>
            <SwitchInputComponent
                optionalField={optionalField}
                onChange={handleChange}
                onChangeDate={handleDateChange}
                onChangeSelect={handleChangeSelect} // Correctly passing the handler expecting value directly
            />
            <StyledError>{error}</StyledError>
        </>
    );
});
