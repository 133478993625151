import { theme, breakpoint, useLogoutHandler } from "@utilities";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { StyledBorderButton } from "../StyledComponents";
import { IconSetupLoader } from "../Svg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, loadAccountDetail, selectAccount } from "@redux";
import { useEffect } from "react";

export const SetupLoaderComponent = () => {
    const handleLogout = useLogoutHandler();
    const dispatch = useDispatch<AppDispatch>();
    const account = useSelector(selectAccount);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (account?.account?.profile_status !== "success") {
                await dispatch(loadAccountDetail());
            } else {
                clearInterval(interval);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [account, dispatch]);

    return (
        <Container>
            <div className="wrapper">
                <div className="logo">
                    <img
                        src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg"
                        alt="logo"
                        className="logoItem logoColor"
                    />
                </div>
                <ButtonWrapper>
                    <NavLink style={{ width: "100%" }} to="/login">
                        <StyledBorderButton
                            borderRadius="4px"
                            color="transparent"
                            textColor={theme.colors.main}
                            style={{ marginTop: "0px" }}
                            onClick={handleLogout}
                        >
                            Logout
                        </StyledBorderButton>
                    </NavLink>
                </ButtonWrapper>
            </div>
            <DefaultLayout>
                <StyledOuterWrapper>
                    <StyledInnerWrapper>
                        <StyledLogoWrapper>
                            <img
                                src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg"
                                alt="logo"
                                className="logoItem2 logoColor"
                            />
                        </StyledLogoWrapper>
                        <StyledCenteredContainer>
                            <IconSetupLoader />
                            <div>Setting up Profile...</div>
                        </StyledCenteredContainer>
                    </StyledInnerWrapper>
                </StyledOuterWrapper>
            </DefaultLayout>
        </Container>
    );
};

const Container = styled.div`
    background-color: #ffffff;
    width: 100vw;
    height: 88.6vh;

    .logo {
        height: 35px;
        position: relative;

        .logoItem {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 147px;

            ${breakpoint.breakIpadPro`
                width:300px;
            `}

            ${breakpoint.breakTablet`
            width:300px;
            `}

            ${breakpoint.breakLarge`
                width:350px;
            `}
        }

        .logoItem2 {
            width: 260px;

            ${breakpoint.breakIpadPro`
                width:300px;
            `}

            ${breakpoint.breakTablet`
            width:300px;
            `}

            ${breakpoint.breakLarge`
                width:350px;
            `}
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 25px 15px;

        ${breakpoint.breakMobileMedium`
            width: ${(props) => props.theme.widths.wide_700};
        `}

        ${breakpoint.breakTablet`
            padding: 25px 0;
        `}

        ${breakpoint.breakIpadPro`
            width: ${(props) => props.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(props) => props.theme.widths.wide_1110};
        `}
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const DefaultLayout = styled.div`
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/images/login-background.png");
`;

const ButtonWrapper = styled.div`
    width: 170px;
    position: relative;
    ${breakpoint.breakOnlyMobile`
        width : 30%;
    `}
`;

const StyledOuterWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 80px 16px;
`;

const StyledInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 540px;
    background-color: #ffffff;
    padding: 64px 10px;
    border-radius: 4px;
`;

const StyledLogoWrapper = styled.div`
    height: 35px;

    .logoItem2 {
        width: 260px;

        @media (min-width: 768px) {
            width: 300px;
        }

        @media (min-width: 1024px) {
            width: 350px;
        }
    }
`;

const StyledCenteredContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
