import styled from "styled-components";
import { memo } from "react";
import moment from "moment";

import { BinSymbolCommon, PointCommon } from "@components";
import { IFareCart } from "@interfaces";
import { breakpoint } from "@utilities";
import _ from "lodash";

interface IPropsCartItem {
    cart: IFareCart;
    onClickDeleteBtn?: () => void;
    onClick?: () => void;
    children?: string | JSX.Element | JSX.Element[];
}
interface IProps {
    quantity: number;
    totalPrice: number;
    handleIncrease: () => void;
    handleDecrease: () => void;
}

export const IncreaseCartComponent = memo((props: IProps) => {
    const { quantity, totalPrice, handleIncrease, handleDecrease } = props;

    return (
        <StyledIncreaseCart>
            <div className="qty">
                <PointCommon onClick={handleDecrease}>-</PointCommon>
                <span>{quantity}</span>
                <PointCommon onClick={handleIncrease}>+</PointCommon>
            </div>
            <div className="price">${totalPrice.toFixed(2)}</div>
        </StyledIncreaseCart>
    );
});

interface ICartItemCostProps {
    totalPrice: number;
}

export const CartItemCostComponent = memo((props: ICartItemCostProps) => {
    const { totalPrice } = props;

    return (
        <StyledIncreaseCart>
            <div className="price">${totalPrice.toFixed(2)}</div>
        </StyledIncreaseCart>
    );
});

interface ICartItemInfo {
    totalQty: number;
}

export const CartItemInfoComponent = (props: ICartItemInfo) => (
    <StyledCartItemInfo>Total: {props.totalQty}</StyledCartItemInfo>
);

export const CartItemComponent = (props: IPropsCartItem) => {
    const { cart, onClick, onClickDeleteBtn, children } = props;
    const date = moment(cart.booking_date).format("DD/MM/YYYY");

    const findFareName = () => {
        const matchedProduct = _.find(cart.product?.json?.faresprices, (product) => {
            return String(product.productPricesDetailsId) === String(cart.product_price_details_id);
        });
        return matchedProduct;
    };

    const fareName = findFareName();

    return (
        <Info className="infoWrapper">
            <div className="info">
                <div className="img">
                    <img src={cart.product?.json?.productImagePath} alt="" onClick={onClick} />
                    {onClickDeleteBtn && (
                        <div className="bin" onClick={onClickDeleteBtn}>
                            <BinSymbolCommon />
                        </div>
                    )}
                </div>
                <div className="main">
                    <p className="title" onClick={onClick}>
                        {cart.product?.json?.name + " - " + fareName?.fareName}
                    </p>
                    <div className="date">
                        <img
                            src="/images/cart/fi_calendar.svg"
                            alt="fi_calendar.svg"
                            className="dateIcon"
                        />
                        <span>{date}</span>
                    </div>
                    {children}
                </div>
            </div>
        </Info>
    );
};

const Info = styled.div`
    background-color: ${(props) => props.theme.colors.gray_6};
    padding-bottom: 0px !important;
    margin-bottom: 16px;
    .info {
        display: flex;
        background-color: #fff;

        .main {
            padding: 12px 16px 0 13px;

            ${breakpoint.breakLarge`
                padding: 25px 33px 0 33px;
                flex: 698;
            `}
        }

        .img {
            position: relative;
            flex: 1;
            cursor: pointer;

            ${breakpoint.breakLarge`
                flex: 302;
            `}

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                position: absolute;
            }

            .bin {
                position: absolute;
                top: 6px;
                left: 6px;
                cursor: pointer;
            }
        }
    }

    .main {
        flex: 2;

        .title {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            cursor: pointer;

            ${breakpoint.breakLarge`
                font-size: 18px;
                font-weight: 700;
                line-height: 23px;
            `}
        }

        .date {
            display: flex;
            align-items: center;
            border-bottom: ${(props) => `1px solid ${props.theme.colors.gray_5}`};
            padding: 12px 0;

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                margin-left: 10px;
                color: ${(props) => props.theme.colors.main};
            }
        }
    }
`;

const StyledIncreaseCart = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

    .qty {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;

        span {
            margin: 0 10px;
        }
    }

    .price {
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        color: ${(props) => props.theme.colors.main};
    }
`;

const StyledCartItemInfo = styled.p`
    font-size: 16px;
    font-weight: 700;
    padding: 10px 0;
`;
