import { Formik, FieldArray, Form } from "formik";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    BinSymbolCommon,
    CustomerDetailFormComponent,
    PointCommon,
    StyledButton,
    TotalPriceComponent,
} from "@components";
import { PATH_QUOTES } from "@routes";
import { breakpoint, useWindowDimensions } from "@utilities";
import { CartConstants } from "@constants";
import {
    addCart,
    checkPayment,
    fetchCustomer,
    resetCart,
    selectCart,
    selectSavedCarts,
    updateAndFetchCustomer,
    updateSavedCart,
    useAppDispatch,
    useAppSelector,
} from "@redux";
import * as Yup from "yup";
import { externalApi } from "@api";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { customerDetailSchema } from "@validations";
import { ISetCustomerData } from "@interfaces";
import { convertCustomerInfoToData } from "src/Utilities/cartHelper";
import moment from "moment";
import _ from "lodash";

interface IProps {
    moveTo: (step: number) => void;
}

export const CheckoutModule = (props: IProps) => {
    const { moveTo } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cartRedux = useAppSelector(selectCart);
    const { carts, customers } = cartRedux;
    const saveCart = useAppSelector(selectSavedCarts);
    const { width } = useWindowDimensions();

    const [countriesData, setCountriesData] = useState([]);
    const [isShowErrorDate, setIsShowErrorDate] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchCustomer());
        dispatch(checkPayment());
    }, [cartRedux.carts.length]);

    const handleSwitchToPayment = () => {
        moveTo(CartConstants.PAYMENT);
    };

    const handleShowCustomerDetail = (customerId: string) => {
        const customerForm = document.querySelector(`#customer_${customerId}`);
        if (customerForm) {
            //@ts-ignore
            customerForm.style.top = "0";
            //@ts-ignore
            customerForm.style.opacity = "1";
        }
    };
    const handleSaveCart = () => {
        const idCard = saveCart.currentSavedCartId;
        if (idCard) {
            dispatch(updateSavedCart(cartRedux, idCard));
        } else {
            dispatch(addCart(cartRedux));
        }
        dispatch(resetCart());
        navigate(PATH_QUOTES);
    };

    const initialValues: { customers: ISetCustomerData[] } = {
        customers:
            customers.length > 0
                ? customers.map(convertCustomerInfoToData)
                : [
                      {
                          firstName: "",
                          lastName: "",
                          dateOfBirth: null,
                          email: "",
                          customerIndex: 0,
                          phoneNumber: "",
                          postalCode: "",
                          countryCode: "",
                      },
                  ],
    };
    useEffect(() => {
        (async () => {
            const res = await externalApi.getCountriesDate();
            setCountriesData(res.data);
        })();
    }, []);

    const getDateValue = (dateOfBirth: string | Date | null | undefined): Date | null => {
        if (dateOfBirth === undefined) {
            return null;
        }
        if (dateOfBirth && typeof dateOfBirth === "string") {
            return new Date(dateOfBirth);
        }
        if (dateOfBirth instanceof Date) {
            return dateOfBirth;
        }

        return null;
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                customers: Yup.array().of(customerDetailSchema),
            })}
            onSubmit={async (values, { setSubmitting, validateForm }) => {
                try {
                    const validationResult = await validateForm();
                    if (!_.isEmpty(validationResult)) {
                        return;
                    }
                    const formattedValues = {
                        ...values,
                        customers: values.customers.map((customer) => ({
                            ...customer,
                            dateOfBirth: customer.dateOfBirth
                                ? moment(customer.dateOfBirth).format("DD-MMM-YYYY") // Format the date before submission
                                : null,
                        })),
                    };
                    await dispatch(updateAndFetchCustomer(formattedValues.customers));
                } catch (error) {
                    console.error(error);
                    ErrorToast({ Message: "Failed to update customers" });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({
                values,
                isValid,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
            }) => {
                return (
                    <Form>
                        <Container>
                            <div className="wrapper">
                                <Info>
                                    <FieldArray
                                        name="customers"
                                        render={(arrayHelpers) => {
                                            return (
                                                <User>
                                                    {values.customers.map((customer, index) => {
                                                        if (width >= 767) {
                                                            const dateValue = getDateValue(
                                                                customer && customer.dateOfBirth,
                                                            );
                                                            const handleChangeDate = (
                                                                date: Date | null,
                                                                index: number,
                                                            ) => {
                                                                setIsShowErrorDate(true);

                                                                const formattedDate = date
                                                                    ? moment(date).format(
                                                                          "DD-MMM-YYYY",
                                                                      )
                                                                    : null;

                                                                setFieldValue(
                                                                    `customers[${index}].dateOfBirth`,
                                                                    formattedDate,
                                                                );
                                                            };
                                                            return (
                                                                <div
                                                                    className="listCustomerDetail"
                                                                    key={
                                                                        customer.customerIndex ||
                                                                        index
                                                                    }
                                                                >
                                                                    <Container2>
                                                                        <Header>
                                                                            <p className="heading">
                                                                                {customer &&
                                                                                customer.customerIndex ===
                                                                                    0
                                                                                    ? "Lead Customer Details"
                                                                                    : "Additional Customer Details"}
                                                                            </p>
                                                                            <div className="closeBtn">
                                                                                &times;
                                                                            </div>
                                                                            {!(
                                                                                customer &&
                                                                                customer.customerIndex ===
                                                                                    0
                                                                            ) && (
                                                                                <div
                                                                                    className="bin"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.remove(
                                                                                            index,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <BinSymbolCommon />
                                                                                </div>
                                                                            )}
                                                                        </Header>
                                                                        <FormContainer>
                                                                            <CustomerDetailFormComponent
                                                                                name={`customers[${index}]`}
                                                                                values={customer}
                                                                                countriesData={
                                                                                    countriesData
                                                                                }
                                                                                dateValue={
                                                                                    dateValue
                                                                                }
                                                                                handleChangeDate={(
                                                                                    date,
                                                                                ) =>
                                                                                    handleChangeDate(
                                                                                        date,
                                                                                        index,
                                                                                    )
                                                                                }
                                                                                isLeader={
                                                                                    customer &&
                                                                                    customer.customerIndex ===
                                                                                        0
                                                                                }
                                                                                isShowErrorDate={
                                                                                    isShowErrorDate
                                                                                }
                                                                                handleChange={
                                                                                    handleChange
                                                                                }
                                                                                handleBlur={
                                                                                    handleBlur
                                                                                }
                                                                                handleSubmit={
                                                                                    handleSubmit
                                                                                }
                                                                            />
                                                                        </FormContainer>
                                                                    </Container2>
                                                                </div>
                                                            );
                                                        } else {
                                                            const fullName = (
                                                                customer?.firstName +
                                                                " " +
                                                                customer?.lastName
                                                            ).trim();
                                                            if (fullName === "") {
                                                                return (
                                                                    <div
                                                                        className="editUser"
                                                                        key={customer.customerIndex}
                                                                    >
                                                                        <SelectTag
                                                                            onClick={() =>
                                                                                handleShowCustomerDetail(
                                                                                    customer.customerIndex.toString(),
                                                                                )
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {customer.customerIndex ===
                                                                                0
                                                                                    ? "Click to enter lead customer details"
                                                                                    : "Click to enter customer details"}
                                                                            </span>
                                                                            <img
                                                                                src="/images/Vector.svg"
                                                                                alt="Vector"
                                                                            />
                                                                        </SelectTag>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div
                                                                        key={customer.customerIndex}
                                                                        className="userItem"
                                                                        onClick={() =>
                                                                            handleShowCustomerDetail(
                                                                                customer.customerIndex.toString(),
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="name">
                                                                            <h2 className="title">
                                                                                {customer.customerIndex ===
                                                                                0
                                                                                    ? "Lead Customer Details"
                                                                                    : "Additional Customer Details"}
                                                                            </h2>
                                                                            {fullName}
                                                                        </span>
                                                                        <PointCommon
                                                                            onClick={() => {
                                                                                //do something
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="/images/fi_check_white.svg"
                                                                                alt="fi_check"
                                                                            />
                                                                        </PointCommon>
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                    })}
                                                    <div className="secondaryActions">
                                                        <div className="btnAddCustomer">
                                                            <img
                                                                src="/images/fi_plus-circle.png"
                                                                alt="fi_plus-circle"
                                                            />
                                                            <span
                                                                onClick={() => {
                                                                    const newIndex =
                                                                        values.customers.filter(
                                                                            (cust) =>
                                                                                cust.customerIndex !==
                                                                                0,
                                                                        ).length + 1;
                                                                    arrayHelpers.push({
                                                                        customerIndex: newIndex,
                                                                        id: Date.now(),
                                                                        isLeader: false,
                                                                        firstName: "",
                                                                        lastName: "",
                                                                        phone: "",
                                                                        email: "",
                                                                        postCode: "",
                                                                        countryCode: null,
                                                                        dateOfBirth: null,
                                                                        qty: 0,
                                                                    });
                                                                }}
                                                            >
                                                                Add customer
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="btnRefresh"
                                                            onClick={() => resetForm()}
                                                        >
                                                            <img
                                                                src="/images/fi_refresh-ccw.svg"
                                                                alt="fi_refresh-ccw"
                                                            />
                                                            <span>Refresh</span>
                                                        </div>
                                                    </div>
                                                    <StyledButton
                                                        color={["#436CB2 0%", "#28B7C3 100%"]}
                                                        borderRadius="4px"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? "Saving ..." : "Save"}
                                                    </StyledButton>
                                                </User>
                                            );
                                        }}
                                    />
                                </Info>
                                <div className="totalPrice">
                                    <TotalPriceComponent
                                        cartItems={carts}
                                        btnTitle="Payment"
                                        disableNext={!isValid || carts.length === 0 || customers.length === 0 || isSubmitting}
                                        onBtnClick={handleSwitchToPayment}
                                        page="checkout"
                                        handleSaveCart={handleSaveCart}
                                    />
                                </div>
                            </div>
                        </Container>
                    </Form>
                );
            }}
        </Formik>
    );
};

const Container = styled.div`
    background-color: #f2f2f2;
    width: 100%;
    .bottom {
        background-color: #fff;
        padding: 32px 15px 41px 15px;
        ${breakpoint.breakTablet`
                    margin-top: -60px;
                    margin-bottom: 60px;
                `}
        .title {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
        }
    }

    .secondaryActions {
        display: flex;
        justify-content: right;
        margin-top: 19px;

        span {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
        }

        .btnAddCustomer,
        .btnRemove,
        .btnRefresh {
            display: flex;
            align-items: center;

            span {
                margin-left: 13px;
            }
        }

        .btnRemove {
            color: ${(p) => p.theme.colors.red};

            ${breakpoint.breakTablet`
                opacity: 0;
                visibility: hidden;
            `}
        }

        .btnRefresh {
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
        }
    }

    .customerDetail {
        position: fixed;
        top: 100%;
        left: 0;
        transition: 300ms;
        width: 100vw;
        padding-top: 45px;
        background-color: #194e59;
        z-index: 1;
        opacity: 0;
        height: 100vh;
        overflow-y: scroll;
        z-index: 10;
    }
    .qty span {
        margin: 0 4px;
    }
    .totalPrice {
        display: flex;
        justify-content: flex-end;
    }
    ${breakpoint.breakTablet`
                background-color: #F2F2F2;
                & > .wrapper {
                    display: flex;
                    width: ${(p) => p.theme.widths.wide_700};
                    margin: 0 auto;
                }
                .customerDetail {
                    display: none;
                }
                .totalPrice {
                    flex: 350;
                }
                .bottom {
                    background-color: transparent;
                    padding: 15px 0 0 0;
                    .title {
                        font-size: 15px;
                    }
                }
            `}
    ${breakpoint.breakIpadPro`
                & > .wrapper {
                    width: ${(p) => p.theme.widths.wide_900};
                }
            `}
            
            ${breakpoint.breakLarge`
                & > .wrapper {
                    width: ${(p) => p.theme.widths.wide_1110};
                }
                .bottom {
                    padding: 32px 0 0 0;
                    .title {
                        font-size: 18px;
                    }
                }
            `}
`;
const Info = styled.div`
    padding: 16px;
    ${breakpoint.breakTablet`
                padding: 0 16px 44px 0;
                flex: 698;
            `}
    ${breakpoint.breakIpadPro`
                padding: 0 33px 44px 0;
            `}
            
            ${breakpoint.breakLarge`
                padding: 0 62px 84px 0;
            `}
`;
const User = styled.div`
    .userItem {
        background-color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: ${(props) => props.theme.colors.black};
        padding: 17px 15px 17px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        align-items: center;
        .title {
            margin-bottom: 4px;
        }
    }
    .listCustomerDetail {
        display: none;
    }
    .editUser {
        position: relative;
        display: flex;
        margin-bottom: 16px;
        .deleteBtn {
            position: absolute;
            height: 52px;
            background-color: #f43f3f;
            padding: 0 15px;
            top: 50%;
            transform: translateY(-50%);
            right: 1px;
        }
    }
    .btnAddCustomer {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        color: ${(props) => props.theme.colors.main};
        margin-right: 18px;
        cursor: pointer;
        span {
            margin-left: 14px;
        }
    }
    ${breakpoint.breakTablet`
                .userItem,
                .editUser {
                    display: none;
                }
                .listCustomerDetail {
                    display: block;
                }
            `}
`;
const Container2 = styled.div`
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
    height: 100%;
    background-color: #fff;

    ${breakpoint.breakTablet`
        border-radius: 0;
        margin-bottom: 30px;
    `}
`;

const Header = styled.div`
    height: 65px;
    background-color: ${(props) => props.theme.colors.main};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    color: #fff;

    ${breakpoint.breakTablet`
        background-color: #fff;
        color: #000;
    `}

    .bin {
        display: none;
        cursor: pointer;

        ${breakpoint.breakTablet`
            display: block;
        `}
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
    }

    .closeBtn {
        font-size: 30px;

        ${breakpoint.breakTablet`
            display: none;
        `}
    }
`;

const FormContainer = styled.div`
    height: calc(100vh - 45px - 65px);
    overflow-y: scroll;
    padding-bottom: 90px;

    ${breakpoint.breakTablet`
        overflow-y: unset;
        padding-bottom: 44px;
        height: auto;
    `}
`;

const SelectTag = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 17px 29px 17px 25px;
    color: ${(p) => p.theme.colors.gray_2};
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;

    .overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .listCustomers {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1;
        background-color: #fff;
        box-shadow: 8px 22px 35px rgba(0, 0, 0, 0.16);
        max-height: 154px;
        overflow-y: overlay;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            appearance: none;
            width: 7px;
        }

        &::-webkit-scrollbar-track {
            appearance: none;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-appearance: none;
            appearance: none;
            background-color: rgba(0, 0, 0, 0.3);
            transition: 0.3s;
            border-radius: 5px;
        }

        .customerItem {
            padding: 17px 29px 17px 25px;
            border-bottom: 1px solid ${(p) => p.theme.colors.gray_4};
            z-index: 3;

            &:first-child {
                border-top: 1px solid ${(p) => p.theme.colors.gray_4};
            }
        }
    }
`;
