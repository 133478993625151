import { Formik } from "formik";
import { LoginFormComponent, StyledLink, LoginLogo } from "@components";
import { IAuthLogin, ILogin } from "@interfaces";
import { loginSchema } from "@validations";
import { AppDispatch, loginV2, toggleLoading, setJwt, initializeAccountDetail } from "@redux";
import { lsEmail, lsJwt } from "@utilities";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { getReturnUrl } from "src/Utilities/routeHelper";

export const Login = () => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const navigate = useNavigate();

    const initialValues: ILogin = {
        email: process.env.REACT_APP_EMAIL || "",
        password: process.env.REACT_APP_PASSWORD || "",
    };

    return (
        <>
            <LoginLogo src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" />
            <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        dispatch(toggleLoading(true));
                        const res = (await dispatch(loginV2(values))) as {
                            payload: IAuthLogin;
                        };
                        //@ts-ignore
                        if (!res.payload.data.accessToken) {
                            setErrors({
                                password: "Please confirm username and password are correct",
                                email: "please confirm username and password are correct",
                            });
                        } else {
                            lsJwt.setItem(res.payload.data);
                            await dispatch(setJwt(res.payload.data));
                            lsEmail.setItem(values.email);
                            await dispatch(initializeAccountDetail());
                            navigate(getReturnUrl(location));
                        }
                    } catch (error: any) {
                        ErrorToast({    
                            Message: error.title || "An unexpected error occurred",
                        });
                    } finally {
                        setSubmitting(false);
                        dispatch(toggleLoading(false));
                    }
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    errors,
                    touched,
                }) => {
                    return (
                        <div style={{ width: "100%" }}>
                            <LoginFormComponent
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                                errors={errors}
                                touched={touched}
                            />
                        </div>
                    );
                }}
            </Formik>
            <StyledLink>
                Don't have an account? <NavLink to={"/signup" + location.search}> Sign up</NavLink>,
                to start selling today.
            </StyledLink>
        </>
    );
};
