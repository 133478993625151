import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAgentStatusResponse } from "@interfaces";
import { RootState } from ".";
import { authApi } from "@api";

interface IState {
    agentDetail: IAgentStatusResponse | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: IState = {
    agentDetail: null,
    isLoading: false,
    error: null,
};

export const fetchAgentDetail = createAsyncThunk<IAgentStatusResponse, void>(
    "auth/fetchAgentDetail",
    async (_, { rejectWithValue }) => {
        try {
            const res = await authApi.agentDetail();
            return res.data as IAgentStatusResponse;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || "Error fetching agent token");
        }
    },
);
const agent = createSlice({
    name: "agentDetail",
    initialState: initialState,
    reducers: {
        setAgentDetail: (state, action: PayloadAction<IAgentStatusResponse>) => {
            state.agentDetail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchAgentDetail.fulfilled,
                (state, action: PayloadAction<IAgentStatusResponse>) => {
                    state.agentDetail = action.payload;
                    state.isLoading = false;
                },
            )
            .addCase(fetchAgentDetail.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAgentDetail.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload || "Failed to fetch agentInfo";
                state.isLoading = false;
            });
    },
});

export const selectAgentDetail = (state: RootState) => state.agentDetail;

export const { setAgentDetail } = agent.actions;

export default agent.reducer;
