import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface DropdownProps {
    options: { label: string; value: string }[];
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

const Dropdown = ({
    options,
    value,
    onChange,
    placeholder = "Select an option",
}: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (value: string) => {
        onChange(value);
        setIsOpen(false);
    };

    return (
        <DropdownWrapper ref={dropdownRef}>
            <DropdownHeader onClick={toggleDropdown}>
                {value ? options.find((option) => option.value === value)?.label : placeholder}
                <StyledArrow isOpen={isOpen} />
            </DropdownHeader>
            {isOpen && (
                <DropdownList>
                    {options.map((option) => (
                        <DropdownItem key={option.value} onClick={() => handleSelect(option.value)}>
                            {option.label}
                        </DropdownItem>
                    ))}
                </DropdownList>
            )}
        </DropdownWrapper>
    );
};

export default Dropdown;

// Styled Components
const DropdownWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const StyledArrow = styled(KeyboardArrowDownIcon)<{ isOpen: boolean }>`
    transition: transform 0.5s;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    color: #31b4b9;
`;

const DropdownHeader = styled.div`
    padding: 15px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
`;

const DropdownList = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
`;

const DropdownItem = styled.li`
    padding: 10px;
    cursor: pointer;

    &:hover {
        background: #f5f5f5;
    }
`;
