import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import {
    StyledBookingDetail,
    MenuAccountComponent,
    BreadcrumbsCommon,
    ClipLoaderCommon,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_HOME, PATH_QUOTES } from "@routes";
import { selectStatusApp, useAppSelector } from "@redux";
import { breakpoint, useWindowDimensions } from "@utilities";
import { phpHelper } from "src/Utilities/phpHelper";
interface ICustomerOrder {
    email: string;
    firstName: string;
    lastName: string;
    phone: string | number;
    productTags: string | null;
    orderId: string | number;
    bookingReference: string;
    salesAgentName: string;
    salesAgentEmail: string;
    salesBranchCode: string;
    brand: string | null;
    datePurchased: string;
    earliestTravelDate: string;
    latestTravelDate: string;
    vouchersPDF: string | null;
    vouchersHTML: string | null;
    orderStatus: string;
    totalAmount: number;
    paidAmount: number;
    netTotal: string | null;
    vouchers: [{ productQty: number }];
    commissionAmount: string | null;
}
export const SavedCartDetail = () => {
    // page hooks
    const { width } = useWindowDimensions();
    const { id } = useParams<{ id: string }>();
    // page redux
    const statusApp = useAppSelector(selectStatusApp).status;
    // page state
    const [quotes, setQuotes] = useState<ICustomerOrder[] | []>([]);
    console.log(id);
    //page variable
    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Quotes",
            link: PATH_QUOTES,
        },
        {
            title: "Details",
            link: `${PATH_QUOTES}/${id}`,
        },
    ];

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const res = (await phpHelper.getQuotes()) as { data: ICustomerOrder[] };
                setQuotes(res.data); // Save the fetched data into state
                console.log("Quotes Data:", res.data);
            } catch (error) {
                console.error("Error fetching vouchers:", error);
            }
        };
        fetchQuotes();
    }, []);

    if (!quotes) {
        return (
            <AppModuleLayout>
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            </AppModuleLayout>
        );
    }

    return (
        <AppModuleLayout>
            <StyledBookingDetail isLogin={true}>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsModel} />
                    </div>
                    <div className="menu">
                        <MenuAccountComponent />
                    </div>
                    <div className="detailWrapper">
                        <Link to={PATH_QUOTES} className="navigateBtn">
                            <img src="/images/fi_arrow-left.png" alt="" />
                            <span>Quotes</span>
                        </Link>
                        {/* <SavedCartItemModule data={voucher} /> */}
                        <Container>
                            <div className="userInfoNPrice">
                                <div className="userInfo">
                                    <p className="subHeading">Lead customer</p>
                                    <p className="email">
                                        {quotes[0]?.firstName + " " + quotes[0]?.lastName}
                                    </p>
                                    <p className="email">{quotes[0]?.email}</p>
                                    <p className="email">+{quotes[0]?.phone}</p>
                                    {quotes.length > 1 && (
                                        <>
                                            <p style={{ marginTop: "5px" }} className="subHeading">
                                                Additional customer
                                            </p>

                                            {quotes.map((customer, index) => {
                                                if (index === 0) return <></>; // bỏ thằng đàu vì nó là lead customer
                                                return (
                                                    <p className="additionalCustomer" key={index}>
                                                        {`${customer.firstName} ${customer.lastName}`}
                                                    </p>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                                <div className="totalPrice">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="field">Number of Items</div>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <div className="value">
                                                        {quotes[0]?.vouchers[0].productQty} Items
                                                    </div>
                                                </td>
                                            </tr>
                                            {statusApp && (
                                                <>
                                                    {(() => {
                                                        // Calculate Total RRP and Total Net as numbers
                                                        const totalRRP = parseFloat(
                                                            quotes[0]?.paidAmount?.toString() ||
                                                                "0",
                                                        );
                                                        const totalNet = parseFloat(
                                                            quotes[0]?.netTotal?.toString() || "0",
                                                        );

                                                        // Calculate Commission Percent and Value
                                                        const commissionPercent = totalRRP
                                                            ? ((totalRRP - totalNet) / totalRRP) *
                                                              100
                                                            : 0;
                                                        const commissionValue = totalRRP - totalNet;

                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <div className="field">
                                                                            Total Net:
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="value">
                                                                            ${totalNet.toFixed(2)}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="field">
                                                                            Commission
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="commissionPrice">
                                                                            {commissionPercent.toFixed(
                                                                                2,
                                                                            )}
                                                                            %
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="value commissionPrice">
                                                                            $
                                                                            {commissionValue.toFixed(
                                                                                2,
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })()}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="total">
                                        <span className="text">Total Sell Price</span>
                                        <span className="value">
                                            ${quotes[0]?.totalAmount.toFixed(2)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        {width > 1024 && (
                            <div>
                                <p className="heading1">Quote Detail</p>
                                <Info className="infoWrapper">
                                    <div className="info">
                                        <div className="img">
                                            {/* <img
                                                src={cart.product?.json?.productImagePath}
                                                alt=""
                                                onClick={onClick}
                                            /> */}
                                        </div>
                                        <div className="main">
                                            {/* <p className="title" onClick={onClick}>
                                                {cart.product?.json?.name +
                                                    " - " +
                                                    fareName?.fareName}
                                            </p> */}
                                            <div className="date">
                                                <img
                                                    src="/images/cart/fi_calendar.svg"
                                                    alt="fi_calendar.svg"
                                                    className="dateIcon"
                                                />
                                                {/* <span>{date}</span> */}
                                            </div>
                                            {/* {children} */}
                                        </div>
                                    </div>
                                </Info>
                            </div>
                        )}
                    </div>
                </div>
            </StyledBookingDetail>
        </AppModuleLayout>
    );
};

const Container = styled.div`
    .generalInfo {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 28px 0;

        img {
            margin-bottom: 27px;

            ${breakpoint.breakLarge`
                width: 124px;
                margin-bottom: 6px;
            `}
        }

        .userName {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            color: ${(props) => props.theme.colors.black};
            margin-bottom: 2px;

            ${breakpoint.breakLarge`
                font-size: 24px;
                line-height: 31px;
                margin-bottom: 14px;
            `}
        }

        .bookingId {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            color: ${(props) => props.theme.colors.main};
            margin-bottom: 2px;

            ${breakpoint.breakLarge`
                font-size: 36px;
                line-height: 47px;
                margin-bottom: 11px;
            `}
        }

        .bookingDate {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: ${(props) => props.theme.colors.gray_2};
        }
    }

    .userInfo {
        margin-top: 16px;
        background-color: #fff;
        padding: 18px 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: ${(props) => props.theme.colors.gray_1};
        text-align: left;

        ${breakpoint.breakTablet`
            flex: 1;
            padding: 15px 0 7px 0;
            font-size: 12px;
            line-height: 20px;
        `}

        ${breakpoint.breakLarge`
            padding: 21px 0 21px 0;
            font-size: 16px;
            line-height: 21px;
        `}

        .subHeading {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            color: ${(props) => props.theme.colors.black};
            margin-bottom: 4px;
            padding-top: 4px;

            ${breakpoint.breakTablet`
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 0px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
                font-weight: 700;
                line-height: 21px;
                margin-bottom: 4px;
            `}
        }

        .email {
            margin-bottom: 4px;

            ${breakpoint.breakTablet`
                margin-bottom: 0;
            `}

            ${breakpoint.breakLarge`
                margin-bottom: 4px;
            `}
        }

        .phoneNumber {
            margin-bottom: 12px;

            ${breakpoint.breakTablet`
                margin-bottom: 3px;
            `}

            ${breakpoint.breakLarge`
                margin-bottom: 17px;
            `}
        }

        & > p {
            ${breakpoint.breakTablet`
                padding-left: 17px;
            `}
        }
    }

    .totalPrice {
        padding: 15px 0 15px 0;
        background-color: #fff;
        margin-top: 16px;
        flex: 1;

        ${breakpoint.breakTablet`
            margin-left: 18px;
        `}

        ${breakpoint.breakIpadPro`
            margin-left: 18px;
        `}

        ${breakpoint.breakLarge`
            padding: 21px 0 0 0;
        `}

        table {
            padding: 0 17px;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 19px;
            color: ${(props) => props.theme.colors.gray_1};

            ${breakpoint.breakTablet`
                font-size: 13px;
                line-height: 13px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
                line-height: 26px;
            `}
        }

        tr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 17px;

            .commissionPrice {
                color: #f43f3f;
            }
        }

        .total {
            padding: 14px 17px 0 17px;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            color: ${(props) => props.theme.colors.black};
            display: flex;
            justify-content: space-between;
            border-top: 1px solid ${(props) => props.theme.colors.gray_5};

            ${breakpoint.breakTablet`
                font-size: 14px;
                line-height: 15px;
            `}

            ${breakpoint.breakLarge`
                padding: 16px 17px 0 17px;
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;
            `}

            .value {
                font-weight: 700;
                color: ${(props) => props.theme.colors.main};
            }
        }
    }
`;

const Info = styled.div`
    background-color: ${(props) => props.theme.colors.gray_6};
    padding-bottom: 0px !important;
    margin-bottom: 16px;
    .info {
        display: flex;
        background-color: #fff;

        .main {
            padding: 12px 16px 0 13px;

            ${breakpoint.breakLarge`
                padding: 25px 33px 0 33px;
                flex: 698;
            `}
        }

        .img {
            position: relative;
            flex: 1;
            cursor: pointer;

            ${breakpoint.breakLarge`
                flex: 302;
            `}

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                position: absolute;
            }

            .bin {
                position: absolute;
                top: 6px;
                left: 6px;
                cursor: pointer;
            }
        }
    }

    .main {
        flex: 2;

        .title {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            cursor: pointer;

            ${breakpoint.breakLarge`
                font-size: 18px;
                font-weight: 700;
                line-height: 23px;
            `}
        }

        .date {
            display: flex;
            align-items: center;
            border-bottom: ${(props) => `1px solid ${props.theme.colors.gray_5}`};
            padding: 12px 0;

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                margin-left: 10px;
                color: ${(props) => props.theme.colors.main};
            }
        }
    }
`;
