import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import reducer, { RootState, selectAccount, selectAuth } from "./Slices";
import _ from "lodash";
import {
    checkIsProfileSetupInProgress,
    checkNeedsRealEmail,
    checkShouldVerifyEmail,
} from "src/Utilities/routeHelper";

// Define persist configuration
const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "auth",
        "signIn",
        "paymentStep",
        "favoriteTours",
        "externalData",
        "status",
        "savedCart",
        "booking",
        "tour",
        "agent",
        "agentDetail"
    ],
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, reducer);

// Configure store
const store = configureStore({
    reducer: persistedReducer,
    // Add custom middleware (if needed) and include default middleware
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Optional: depending on your use case
        }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export default store;

// Custom hooks for useDispatch and useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useIsLoggedIn = () => {
    const { jwt } = useAppSelector(selectAuth);
    const { account } = useAppSelector(selectAccount);
    return !_.isNil(account) && !_.isNil(jwt);
};

export const useNeedsRealEmail = () => {
    const isLoggedIn = useIsLoggedIn();
    const { account } = useAppSelector(selectAccount);
    return checkNeedsRealEmail(isLoggedIn, account);
};

export const useShouldVerifyEmail = () => {
    const isLoggedIn = useIsLoggedIn();
    const { account } = useAppSelector(selectAccount);
    return checkShouldVerifyEmail(isLoggedIn, account);
};

export const useIsProfileSetupInProgress = () => {
    const isLoggedIn = useIsLoggedIn();
    const { account } = useAppSelector(selectAccount);
    return checkIsProfileSetupInProgress(isLoggedIn, account);
};
