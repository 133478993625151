import { ICustomerInfo, IFareCart, IPricingInfo, ISetCustomerData } from "@interfaces";

export const calculatePricingInfo = (cartItems: IFareCart[]): IPricingInfo => {
    const totalRRP = cartItems.reduce(
        (accumulator, cartItem) =>
            accumulator +
            cartItem.availability?.productPricingData?.RRP * cartItem.booking_quantity!,
        0,
    );
    const totalNet = cartItems.reduce(
        (accumulator, cartItem) =>
            accumulator +
            (cartItem.availability?.productPricingData.NetRate || 0) * cartItem.booking_quantity!,
        0,
    );
    const commission = totalRRP - totalNet;
    const commissionPercent = totalRRP !== 0 ? (commission / totalRRP) * 100 : 0;
    const totalPrice = totalRRP;

    return {
        totalRRP,
        totalNet,
        commission,
        commissionPercent,
        totalPrice,
    };
};

export const convertCustomerInfoToData = (customerInfo: ICustomerInfo): ISetCustomerData => {
    return {
        firstName: customerInfo.first_name,
        lastName: customerInfo.last_name,
        dateOfBirth: customerInfo.date_of_birth,
        email: customerInfo.email,
        customerIndex: customerInfo.customer_index,
        phoneNumber: `${customerInfo.phone_number}`,
        postalCode: customerInfo.postal_code,
        countryCode: customerInfo.country_code,
    };
};
