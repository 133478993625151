import axios from "axios";

import queryString from "query-string";

const basicFtApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL_V2,

    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },

    paramsSerializer: (params) => queryString.stringify(params),
});

export default basicFtApiClient;