export * from "./filter";

export const SPACE_TOKEN = "/=";
export const PAGINATION = 6;
export const BOOKING_SYSTEM = "CMS";
export const BOOKING_SYSTEMS = ["CMS", "Generic", "Respax", "Clockwork", "IBIS"];

//cart
export const CartConstants = {
    CART: 1,
    CHECKOUT: 2,
    ORDER_SUCCESS: 4,
    PAYMENT: 3,
};

export const DEFAULT_RETURN_PATH = "/";
//duration toast message
export const DURATION_TIME = 30000;
//google login
export const GOOGLE_OAUTH_CLIENT_ID="529254559269-u14b4pdf8oq5rlo2pc8ru9u1bn2cikqe.apps.googleusercontent.com";
export const APPLE_OAUTH_CLIENT_ID="com.freelanceadventure.app.service";
//payment
export const PAYMENT_OPTION = "Payment";
export const SHARE_OPTION = "Share";
export const LINk_FACEBOOK = "https://www.facebook.com/freelancetravelinstantbookingsystem";
export const LINK_INSTAGRAM = "https://www.instagram.com/_freelancetravel";
export const LINk_LINKEDIN = "https://www.linkedin.com/company/freelancetravel";
export const CONSTANT_INFORMATION ="Global Travel Marketplace Pty Ltd t/as Freelance Travel ABN 37 108 915 594 | 60 Baxter Street, , Fortitude Valley QLD 4509 © 2024 Freelance Travel Powered by Global Travel Marketplace Pty Ltd";
export const CONSTANT_CONTACT= "support@freelancetravel.com";
export const LOGGER_HOOK =
    "https://thingproxy.freeboard.io/fetch/https://api.sendgrid.com/v3/mail/send";
export const LOGGER_TOKEN =
    "Bearer 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";

export const PHP_HELPER = "https://confirmation.freelancetravel.com/api";
//  export const PHP_HELPER = "http://localhost:8000/api";
export const PHP_TOKEN = "rRraPA72vHpJDTzZwH09lZ4CcF38Jg";
export const PHP_AGENT_PATH = "/payment-success/agent";
export const PHP_CUSTOMER_PATH = "/payment-success/customer";
export const RETURN_TO_PARAM_KEY = "return_to";