import { externalApi } from "@api";
import { setHomepage, useAppDispatch } from "@redux";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const PublicRoute = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            const data = await externalApi.getDataHomePage();
            dispatch(setHomepage(data.data));
        })();
    }, []);

    return (
        <Container>
            {/* Render child routes */}
            <Outlet />
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
`;

export default PublicRoute;
