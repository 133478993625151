import { IAddCart, ICreateOrderData, ISetCustomerData } from "@interfaces";
import ftApiClient from "./ftApiClient";

export const cartApi = {
    addCart: (params: IAddCart | IAddCart[]) => {
        const url = "/cart/items";
        return ftApiClient.post(url, params);
    },
    getCart: () => {
        const url = "/cart/items";
        return ftApiClient.get(url);
    },
    saveCart: (cartItemId: number | string, params: { bookingData: any; quantity: number }) => {
        const url = `cart/items/${cartItemId}`;
        return ftApiClient.put(url, params);
    },
    removeCart: (cartItemId: number | string) => {
        const url = `cart/items/${cartItemId}`;
        return ftApiClient.delete(url);
    },
    updateCustomer: (params: ISetCustomerData[]) => {
        const url = "cart/customers";
        return ftApiClient.put(url, params);
    },
    getCustomer: () => {
        const url = "cart/customers";
        return ftApiClient.get(url);
    },
    createOrder: (data: ICreateOrderData) => {
        const url = "cart/order";
        return ftApiClient.post(url, data);
    }
};
