import { AppDispatch, clearSharedToken, logout, removeJwt, resetCart, resetSavedCart } from "@redux";
import { useDispatch } from "react-redux";
import { ssPathname } from "./storage";
import { useNavigate } from "react-router-dom";
import { PATH_LOGIN } from "@routes";

export const useLogoutHandler = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    return async () => {
        ssPathname.removeItem();
        await dispatch(logout());
        await dispatch(removeJwt());
        dispatch(clearSharedToken());
        dispatch(resetSavedCart());
        dispatch(resetCart());
        navigate(PATH_LOGIN);
    };
};
