import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { StyledButton, TotalPriceComponent } from "@components";
import { breakpoint } from "@utilities";
import { CartConstants } from "@constants";
import {
    AppDispatch,
    checkOptionalField,
    selectCart,
    submitCartDataAndFetch,
    useAppSelector,
} from "@redux";
import { FieldArray, Form, Formik } from "formik";
import NewCartItem from "./NewCartItemModule";
import { IFareCart } from "@interfaces";

interface IProps {
    moveTo: (step: number) => void;
    handleDeletePopUp: (cartDeleteId?: string | number) => void;
}

export const CartModule = (props: IProps) => {
    //prop
    const { moveTo, handleDeletePopUp } = props;
    //page hook
    const dispatch = useDispatch<AppDispatch>();
    //redux state
    const carts = useAppSelector(selectCart).carts;

    useEffect(() => {
        dispatch(checkOptionalField());
    }, [carts.length]);
    const handleSwitchToCheckout = () => {
        moveTo(CartConstants.CHECKOUT);
    };

    const handleSubmit = async (cartItems: IFareCart[]) => {
        const payload = cartItems.map((ci) => ({ itemId: ci.id, bookingData: ci.bookingData }));
        await dispatch(submitCartDataAndFetch(payload));
    };
    const initialValues = {
        cartItems: carts.map((c) => ({
            ...c,
            bookingData: c.booking_data,
        })),
    };

    return (
        <Container>
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => handleSubmit(values.cartItems)}
                    enableReinitialize={true} 
                >
                    {({ values, isSubmitting, resetForm }) => (
                        <Form>
                            <ListProducts>
                                <FieldArray
                                    name="cartItems"
                                    render={() => (
                                        <div>
                                            {values.cartItems &&
                                                values.cartItems.map((cartItem, index) => {
                                                    return (
                                                        <NewCartItem
                                                            key={index}
                                                            item={cartItem}
                                                            arrayIndex={index}
                                                            arrayName="cartItems"
                                                            handleDeletePopUp={handleDeletePopUp}
                                                        />
                                                    );
                                                })}

                                            <div className="secondaryActions">
                                                <div
                                                    className="btnRefresh"
                                                    onClick={() => resetForm()}
                                                >
                                                    <img
                                                        src="/images/fi_refresh-ccw.svg"
                                                        alt="fi_refresh-ccw"
                                                    />
                                                    <span>Refresh</span>
                                                </div>
                                            </div>
                                            <StyledButton
                                                color={["#436CB2 0%", "#28B7C3 100%"]}
                                                borderRadius="4px"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? "Saving ..." : "Save"}
                                            </StyledButton>
                                        </div>
                                    )}
                                />
                            </ListProducts>
                            <TotalPriceComponent
                                btnTitle="Checkout"
                                disableNext={carts.length === 0 || isSubmitting}
                                cartItems={values.cartItems}
                                onBtnClick={handleSwitchToCheckout}
                                page="cart"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;

    ${breakpoint.breakOnlyMobile`
        flex: 1;
        display: flex;
        flex-direction: column;
    `}

    & > div {
        margin: 0 auto;

        ${breakpoint.breakOnlyMobile`
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
        `}
    }

    ${breakpoint.breakTablet`
        & form {
            display: flex;
        }
    `}

    .secondaryActions {
        display: flex;
        justify-content: right;
        margin-top: 19px;

        span {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
        }

        .btnRemove,
        .btnRefresh {
            display: flex;
            align-items: center;

            span {
                margin-left: 13px;
            }
        }

        .btnRemove {
            color: ${(p) => p.theme.colors.red};

            ${breakpoint.breakTablet`
                opacity: 0;
                visibility: hidden;
            `}
        }

        .btnRefresh {
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
        }
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;

        & > div {
            display: flex;
            width: ${(p) => p.theme.widths.wide_700};
        }
    `}

    ${breakpoint.breakIpadPro`
        & > div {
            width: ${(p) => p.theme.widths.wide_900};
        }
    `}
    
    ${breakpoint.breakLarge`
        & > div {
            width: ${(p) => p.theme.widths.wide_1110};
        }
    `}
`;

const ListProducts = styled.div`
    background-color: ${(props) => props.theme.colors.gray_6};
    padding: 16px 16px 0 16px;

    .heading {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 7px;
    }

    .infoWrapper {
        padding-bottom: 16px;
    }

    .MuiSelect-icon {
        color: ${(props) => props.theme.colors.main} !important;
    }

    .MuiSelect-select.MuiSelect-select {
        font-weight: 700 !important;
        font-size: 16px !important;
        width: 85px;
    }

    .MuiInput-underline:after {
        display: none !important;
    }

    .MuiInput-underline:before {
        border: none !important;
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
        padding: 0 16px 44px 0;
        flex: 698;

        .infoWrapper {
            background-color: #F2F2F2;
        }

        .MuiInput-root {
            display: none;
        }
    `}

    ${breakpoint.breakIpadPro`
        padding: 0 33px 44px 0;
    `}
    
    ${breakpoint.breakLarge`
        padding: 0 62px 84px 0;
    `}
`;
