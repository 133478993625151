import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

import {
    BreadcrumbsCommon,
    ClipLoaderCommon,
    MenuAccountComponent,
    StyledSavedCartPage,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_BOOKING, PATH_BOOKING_DETAIL, PATH_HOME } from "@routes";
import { breakpoint, theme } from "@utilities";
import { getListBooking, AppDispatch } from "@redux";
import { IListBookings } from "@interfaces";
import { NavLink } from "react-router-dom";
import moment from "moment";

export const Booking = () => {
    // page hooks
    const dispatch = useDispatch<AppDispatch>();

    //page state
    const [bookings, setBookings] = useState<IListBookings[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Bookings",
            link: PATH_BOOKING,
        },
    ];

    useEffect(() => {
        fetchBookings();
    }, []);

    const fetchBookings = async () => {
        try {
            const res = await dispatch(getListBooking());
            //@ts-ignore
            unwrapResult(res);
            //@ts-ignore
            setBookings(res.payload);
        } catch {
            setBookings([]);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = (bookingReference: string | null) => {
        if (bookingReference) {
            navigator.clipboard.writeText(bookingReference);
        }
    };

    const formatPurchaseDate = (purchaseDate?: string | null): string => {
        if (!purchaseDate) return "N/A"; // Handle cases where purchaseDate is null or undefined
        const date = new Date(purchaseDate);
        return moment(date).format("DD/MM/YYYY");
    };
    
    return (
        <AppModuleLayout>
            {loading ? (
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            ) : (
                <StyledSavedCartPage>
                    <div className="wrapper">
                        <div className="breadCrumbs">
                            <BreadcrumbsCommon data={breadcrumbsModel} />
                        </div>
                        <div className="subWrapper">
                            <div className="menu">
                                <MenuAccountComponent />
                            </div>
                            <div className="listCarts">
                                <div className="heading">
                                    <p>Bookings</p>
                                </div>
                                {bookings.map((booking, index) => (
                                    <StyledSavedCartWrapper key={index}>
                                        <StyledDateWrapper>
                                            <div className="reference-container">
                                                <div className="reference">
                                                    Booking Reference:
                                                    <span className="bookingReference">
                                                        {booking.bookingReference}
                                                        <CopyButton
                                                            onClick={() =>
                                                                copyToClipboard(
                                                                    booking.bookingReference,
                                                                )
                                                            }
                                                        >
                                                            Copy
                                                        </CopyButton>
                                                    </span>
                                                </div>
                                                <NavLink
                                                    to={`${PATH_BOOKING_DETAIL}/${booking.bookingReference}`}
                                                >
                                                    <img
                                                        src="images/ChevronVectorRight.svg"
                                                        alt="Right arrow"
                                                    />
                                                </NavLink>
                                            </div>
                                            <StyledDateInfo>
                                                <div className="content-container">
                                                    <span className="content">
                                                        Created On:
                                                        <span className="content-data">
                                                            {formatPurchaseDate(booking.purchaseDate)|| "N/A"}
                                                        </span>
                                                    </span>
                                                    <span className="content">
                                                        Agent:
                                                        <span className="content-data">
                                                            {booking.agentUser.name}
                                                        </span>
                                                    </span>
                                                </div>
                                            </StyledDateInfo>
                                        </StyledDateWrapper>
                                        <StyledActionsWrapper>
                                            <StyledTotalPrice>
                                                <div className="title">Total RRP</div>
                                                <div className="price">
                                                    ${booking.totalAmount?.toFixed(2) || "0.00"}
                                                </div>
                                            </StyledTotalPrice>
                                            <StyledVoucherInfo>
                                                <div
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#1F929C",
                                                    }}
                                                >
                                                    {booking.products.length }{" "}
                                                    {booking.products.length === 1
                                                        ? " item"
                                                        : " items"}
                                                </div>
                                            </StyledVoucherInfo>
                                        </StyledActionsWrapper>
                                    </StyledSavedCartWrapper>
                                ))}
                            </div>
                        </div>
                    </div>
                </StyledSavedCartPage>
            )}
        </AppModuleLayout>
    );
};

const StyledSavedCartWrapper = styled.div<{
    isList?: boolean;
}>`
    background-color: white;
    margin-bottom: 24px;
`;

const StyledDateWrapper = styled.div`
    padding: 16px 0;
    padding-left: 20px;
    padding-right: 25px;
    display: flex;
    color: #6e7375;
    gap: 10px;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    .reference-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

    .reference{
        display: flex;
        align-items : center;
        ${breakpoint.breakOnlyMobile`
            display: flex;
            align-items: start;
            flex-direction: column;
        `}
    }

    .bookingReference {
        display: flex;  
        flex-direction : row;
        align-items: center;
        color: #131214;
        font-size: 18px;
        font-weight: bold;
        margin-left: 14px;
        ${breakpoint.breakOnlyMobile`
            margin-left : 0px;
        `}
    }
`;

const CopyButton = styled.button`
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const StyledDateInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    .content-container {
        display: flex;
        color: #4f4f4f;
        font-size: 14px;
        gap: 60px;
        .content-data {
            font-weight: 500;
        }

        .content {
            display: flex;
            gap: 6px;
            ${breakpoint.breakOnlyMobile`
                flex-direction : column;
                gap : 3px;
            `}
        }
    }
`;

const StyledVoucherInfo = styled.div`
    background-color: #e9f8f9;
    padding: 6px 12px;
    border-radius: 50px;
    font-weight: 500;
`;

const StyledTotalPrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .title {
        font-family: DM Sans;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        color: #090a18;
    }

    .price {
        color: ${theme.colors.main};
        font-weight: 500;
        font-size: 16px;
    }
`;

const StyledActionsWrapper = styled.div`
    display: flex;
    padding: 14px 16px 20px 20px;
    gap: 16px;
`;
