import { useState, useEffect } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";

import {
    BreadcrumbsCommon,
    ClipLoaderCommon,
    MenuAccountComponent,
    StyledBorderButton,
    StyledSavedCartPage,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_HOME, PATH_QUOTES } from "@routes";
import { theme } from "@utilities";
import { phpHelper } from "src/Utilities/phpHelper";
import styled from "styled-components";
import { IQuoteDetailResponse } from "src/Interfaces/quoteDetails";
import { selectAccount, useAppSelector } from "@redux";

interface ICustomerOrder {
    email: string;
    firstName: string;
    lastName: string;
    phone: string | number;
    productTags: string | null;
    orderId: string | number;
    bookingReference: string;
    salesAgentName: string;
    salesAgentEmail: string;
    salesBranchCode: string;
    brand: string | null;
    datePurchased: string;
    earliestTravelDate: string;
    latestTravelDate: string;
    vouchersPDF: string | null;
    vouchersHTML: string | null;
    orderStatus: string;
    totalAmount: number;
    paidAmount: number;
    netTotal: number;
    vouchers: [{ productQty: number }];
    commissionAmount: string | null;
}

export const Quotes = () => {
    const [quotes, setQuotes] = useState<ICustomerOrder[] | []>([]);
    const { account } = useAppSelector(selectAccount);
    const [isLoading, setIsLoading] = useState(true);
    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Saved Quotes",
            link: PATH_QUOTES,
        },
    ];

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const res = (await phpHelper.getQuotes(account?.email)) as {
                    data: ICustomerOrder[];
                };
                setQuotes(res.data);
            } catch (error) {
                console.error("Error fetching vouchers:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchQuotes();
    }, []);

    const fetchQuotesDetail = async (bookingReference: string) => {
        setIsLoading(true);
        try {
            const res = (await phpHelper.getQuotesDetail(bookingReference)) as {
                data: IQuoteDetailResponse;
            };
            const quoteUrl = res.data.data.paymentGateway.quoteUrl;
            if (quoteUrl) {
                window.open(quoteUrl, "_blank");
            }
        } catch (error) {
            console.error("Error fetching vouchers:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const copyToClipboard = (bookingReference: string) => {
        navigator.clipboard.writeText(bookingReference);
    };

    return (
        <AppModuleLayout>
            {isLoading ? (
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            ) : (
                <StyledSavedCartPage>
                    <div className="wrapper">
                        <div className="breadCrumbs">
                            <BreadcrumbsCommon data={breadcrumbsModel} />
                        </div>
                        <div className="subWrapper">
                            <div className="menu">
                                <MenuAccountComponent />
                            </div>
                            <div className="listCarts">
                                <div className="heading">
                                    <p>Quotes</p>
                                </div>
                                {quotes.map((quote, index) => (
                                    <StyledSavedCartWrapper key={index}>
                                        <StyledDateWrapper>
                                            <div className="content">
                                                Booking Reference:
                                                <span className="bookingReference">
                                                    {quote.bookingReference}
                                                </span>
                                                <CopyButton
                                                    onClick={() =>
                                                        copyToClipboard(quote.bookingReference)
                                                    }
                                                >
                                                    Copy
                                                </CopyButton>
                                            </div>
                                            <StyledDateInfo>
                                                <div className="content">
                                                    Saved:{" "}
                                                    <span style={{ marginLeft: "5px" }}>
                                                        {quote.datePurchased || "N/A"}
                                                    </span>
                                                </div>
                                                <StyledVoucherInfo>
                                                    <div
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#1F929C",
                                                        }}
                                                    >
                                                        {quote.vouchers[0]?.productQty}{" "}
                                                        {quote.vouchers[0]?.productQty === 1
                                                            ? " item"
                                                            : " items"}
                                                    </div>
                                                </StyledVoucherInfo>
                                            </StyledDateInfo>
                                            <StyledTotalPrice>
                                                <div className="title">Total RRP</div>
                                                <div className="price">
                                                    ${quote.totalAmount?.toFixed(2) || "0.00"}
                                                </div>
                                            </StyledTotalPrice>
                                        </StyledDateWrapper>
                                        <StyledActionsWrapper>
                                            <div className="ButtonContainer">
                                                <button className="CancelButton">Cancel</button>
                                                <StyledBorderButton
                                                    onClick={() =>
                                                        fetchQuotesDetail(quote.bookingReference)
                                                    }
                                                    style={{
                                                        marginTop: "0px",
                                                        padding: "0px 20px",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        border: "1px solid #D9D9D9",
                                                    }}
                                                    textColor="#000000"
                                                    color="transparent"
                                                    borderRadius="4px"
                                                >
                                                    Open details
                                                </StyledBorderButton>
                                            </div>
                                        </StyledActionsWrapper>
                                    </StyledSavedCartWrapper>
                                ))}
                            </div>
                        </div>
                    </div>
                </StyledSavedCartPage>
            )}
        </AppModuleLayout>
    );
};

const StyledSavedCartWrapper = styled.div<{
    isList?: boolean;
}>`
    background-color: white;
    margin-bottom: 24px;
`;

const StyledDateWrapper = styled.div`
    padding: 16px 0;
    padding-left: 20px;
    padding-right: 25px;
    display: flex;
    color: #6e7375;
    gap: 10px;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    .content {
        color: #4f4f4f;
        font-size: 14px;
    }

    .bookingReference {
        color: #131214;
        font-size: 18px;
        font-weight: bold;
        margin-left: 5px;
    }
`;

const CopyButton = styled.button`
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const StyledDateInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const StyledVoucherInfo = styled.div`
    background-color: #e9f8f9;
    padding: 6px 12px;
    border-radius: 50px;
`;

const StyledTotalPrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-family: DM Sans;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        color: #090a18;
    }

    .price {
        color: ${theme.colors.main};
        font-weight: 500;
        font-size: 16px;
    }
`;

const StyledActionsWrapper = styled.div`
    display: flex;
    justify-content: end;
    padding: 14px 16px 20px 0px;

    .ButtonContainer {
        display: flex;
        align-items: center;
        gap: 30px;
    }
    .CancelButton {
        font-size: 16px;
        font-weight: 600;
        color: #31b4b9;
    }
`;
