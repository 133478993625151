import axios from "axios";

import queryString from "query-string";
import { store, fetchSharedToken, fetchAgentToken } from "@redux";
import _ from "lodash";
import { ISharedToken } from "@interfaces";

const wtApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },

    paramsSerializer: (params) => queryString.stringify(params),
});

wtApiClient.interceptors.request.use(async (config) => {
    const state = store.getState();
    let sharedToken = state.sharedToken.sharedToken?.data?.access_token ?? "";
    const agentToken = state.auth.auth?.data?.access_token;
    const integratedStatus = state.account.account?.is_agent_integrated;

    if (_.isEmpty(sharedToken)) {
        const res = (await store.dispatch(fetchSharedToken())) as { payload: ISharedToken };
        sharedToken = res.payload.data.access_token;
    }

    config.headers.Authorization = integratedStatus
        ? `Bearer ${agentToken}`
        : `Bearer ${sharedToken}`;
    return config;
});

wtApiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (!originalRequest._retryCount) {
            originalRequest._retryCount = 0;
        }
        if (
            error.response?.status === 401 &&
            originalRequest.headers.Authorization?.includes("Bearer") &&
            store.getState().account.account?.is_agent_integrated
        ) {
            if (originalRequest._retryCount < 2) {
                originalRequest._retryCount += 1;
                return wtApiClient(originalRequest);
            }
            console.error(
                `Request failed after 2 retries. Error:`,
                error.response?.data || error.message,
            );

            try {
                const res = (await store.dispatch(fetchAgentToken())) as { payload: ISharedToken };
                const newAgentToken = res.payload.data.access_token;
                originalRequest.headers.Authorization = `Bearer ${newAgentToken}`;
                return wtApiClient(originalRequest);
            } catch (fetchError) {
                return Promise.reject(fetchError);
            }
        }

        return Promise.reject(error);
    },
);

export default wtApiClient;
