import axios from "axios";

import queryString from "query-string";
import { store, refreshAccessToken } from "@redux";
import _ from "lodash";

const ftApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL_V2,

    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },

    paramsSerializer: (params) => queryString.stringify(params),
});

ftApiClient.interceptors.request.use((config) => {
    const state = store.getState();
    config.headers.Authorization = `Bearer ${state.auth?.jwt?.accessToken}`;
    return config;
});

ftApiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response.status === 401 && _.isNil(error.config.headers.__isRetry)) {
            const state = store.getState();
            const userId = state.account.account.id;
            await store.dispatch(
                await refreshAccessToken({ refreshToken: state.auth?.jwt?.refreshToken, userId }),
            );
            error.config.headers.__isRetry = true;
            return await ftApiClient(error.config);
        }
        throw error;
    },
);

export default ftApiClient;
