import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CartItemComponent, CartItemCostComponent } from "@components";
import { PATH_DETAIL_TOUR } from "@routes";
import { IFareCart, IPickupLocationFare, OptionalField } from "@interfaces";
import Dropdown from "./CustomDropdown"; // Import the custom dropdown
import { BookingDataField } from "./OptionalFieldModule";
import { breakpoint } from "@utilities";
import _ from "lodash";
import { Field, useField, useFormikContext } from "formik";

interface IPropsCartItem {
    item: IFareCart;
    arrayIndex: number;
    arrayName: string;
    handleDeletePopUp: (cartDeleteId: string | number) => void;
}

const NewCartItem = (props: IPropsCartItem) => {
    const { item, arrayIndex, arrayName, handleDeletePopUp } = props;
    const navigate = useNavigate();
    const { setFieldValue } = useFormikContext();
    const fieldPrefix = `${arrayName}.${arrayIndex}`;
    const [field] = useField(`${fieldPrefix}.bookingData.pickupLocation`);

    const pickupLocationField: OptionalField = {
        value: "",
        apiName: "",
        mandatory: false,
        optionalFieldId: "pickupLocation",
        options: [],
        title: "Pick Up Location",
        type: "select",
    };

    const handleDelete = () => {
        handleDeletePopUp(item.id);
    };
    const handleClick = () => {
        navigate(`${PATH_DETAIL_TOUR}/${item.tdms_product_id}`);
    };

    const bookingQuantity = item.booking_quantity ?? 0;
    const rrp = _.get(item, "availability.productPricingData.RRP", 0);
    const totalPrice = bookingQuantity * rrp;

    return (
        <>
            <CartItemComponent cart={item} onClickDeleteBtn={handleDelete} onClick={handleClick}>
                <CartItemCostComponent totalPrice={totalPrice} />
            </CartItemComponent>
            <CartItemDetails>
                <OptionalFieldsRow>
                    {item.booking_details?.optionalFields?.map(
                        (optionalField: OptionalField, index: number) => (
                            <OptionalFieldContainer key={index}>
                                <FieldLabel htmlFor={`input_${item.id}_${index}`}>
                                    {optionalField.title}{" "}
                                    {optionalField.mandatory && <span>*</span>}
                                </FieldLabel>
                                <Field
                                    name={`${fieldPrefix}.bookingData.${optionalField.optionalFieldId}`}
                                    optionalField={optionalField}
                                    component={BookingDataField}
                                />
                            </OptionalFieldContainer>
                        ),
                    )}
                </OptionalFieldsRow>
                {item.booking_details?.pickupLocations &&
                    item.booking_details?.pickupLocations.length > 0 && (
                        <>
                            <PickupFieldLabel htmlFor={`input-pickup`}>
                                {pickupLocationField.title} <span>*</span>
                            </PickupFieldLabel>
                            <Dropdown
                                options={item.booking_details.pickupLocations.map(
                                    (location: IPickupLocationFare) => ({
                                        label: `${location.PickupTime} ${location.PickupLocation}`,
                                        value: location.PickupID || "",
                                    }),
                                )}
                                value={field.value || ""}
                                onChange={(value: string) => {
                                    setFieldValue(
                                        `${fieldPrefix}.bookingData.pickupLocation`,
                                        value,
                                    );
                                }}
                                placeholder="Select Pickup Location"
                            />
                        </>
                    )}
            </CartItemDetails>
        </>
    );
};

const CartItemDetails = styled.div`
    display: flex;
    flex-wrap: wrap; // Allow wrapping to the next line if necessary
    gap: 16px; // Space between fields
    background: #fff;
    padding: 20px 16px;
    margin-bottom: 24px;

    ${breakpoint.breakOnlyMobile`
        flex-direction: column; 
        gap: 8px;
    `}
`;

const OptionalFieldsRow = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* This creates 2 equal-width columns */
    gap: 16px; /* Adds space between columns */
    width: 100%;
    margin-top: 10px;
    margin-bottom: -12px;
    ${breakpoint.breakOnlyMobile`
            grid-template-columns: repeat(1, 1fr); /* This creates 2 equal-width columns */

    `}
`;

const OptionalFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    min-width: 200px;
    width: 100%; // Ensures that the container stretches to full width

    ${breakpoint.breakOnlyMobile`
        flex-direction: column; // Stack in a column on mobile
        width: 100%;
    `}
`;

const FieldLabel = styled.label`
    font-weight: bold;
    font-size: 16px;
    color: #090a18;
    margin-bottom: 8px;

    span {
        color: red;
    }
`;

const PickupFieldLabel = styled.label`
    font-weight: bold;
    font-size: 16px;
    color: #090a18;
    margin-top: 10px;
    margin-bottom: -8px;
    span {
        color: red;
    }
`;

export default NewCartItem;
