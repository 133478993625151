import { useEffect, useState } from "react";

import { useWindowDimensions } from "@utilities";
import {
    FooterComponent,
    FooterMobileComponent,
    ITag,
    IconSearch,
    IconFileText,
    IconQuote,
    IconUser,
} from "@components";
import { PATH_MENU_SETTING, PATH_BOOKING, PATH_HOME, PATH_QUOTES, PATH_SEARCH_RESULT } from "@routes";


export const FooterModule = () => {
    
    const { width } = useWindowDimensions();
    const [isBar, setIsBar] = useState<boolean>(false);
    const pathName = location.pathname;
    const tagBottom: ITag[] = [
        {
            Icon: IconSearch,
            text: "Search",
            link: PATH_HOME,
        },
        {
            Icon: IconQuote,
            text: "Quotes",
            link: PATH_QUOTES,
        },
        {
            Icon: IconFileText,
            text: "Bookings",
            link: PATH_BOOKING,
        },
        {
            Icon: IconUser,
            text: "Account",
            link: PATH_MENU_SETTING,
        },
    ];
    
    const [bottom, setBottom] = useState<ITag[]>(tagBottom);

    useEffect(() => {
        setBottom(
            tagBottom.map((tag) => {
                let active = false;
                if (pathName === PATH_SEARCH_RESULT) {
                    active = tag.link === PATH_HOME;
                } else {
                    active = pathName === tag.link;
                }
                return { ...tag, active };
            })
        );

        if (
            pathName === PATH_HOME ||
            pathName === PATH_SEARCH_RESULT ||
            pathName === PATH_BOOKING || 
            pathName === PATH_MENU_SETTING ||
            pathName === PATH_QUOTES
        ) {
            setIsBar(true);
        }
    }, []);

    return width >= 767 ? (
        <FooterComponent />
    ) : isBar ? (
        <FooterMobileComponent tagList={bottom} />
    ) : (
        <></>
    );
};
