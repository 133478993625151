import { AppDispatch, selectAuth, useAppSelector } from "@redux";
import { PATH_SIGNUP } from "@routes";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LoadingStage } from "src/Interfaces/shared";
import { initializeAccountDetail, loadAccountDetail, selectAccount } from "@redux";
import styled from "styled-components";
import { buildReturnPathParam } from "src/Utilities/routeHelper";

// Define the ProtectedRoute component
const ProtectedRoute = ({ redirectPath = PATH_SIGNUP }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { jwt } = useAppSelector(selectAuth);
    const { account, loadingStage } = useAppSelector(selectAccount);
    const [ shouldRedirect, setShouldRedirect ] = useState<boolean>(_.isNil(account) || _.isNil(jwt));
    const location = useLocation();

    useEffect(() => {
        if (shouldRedirect) return;
        // fire account detail
        switch(loadingStage) {
            case LoadingStage.NOT_INITIALIZED:
                if (_.isNil(jwt)) {
                    dispatch(initializeAccountDetail());
                }
                break;
            case LoadingStage.INITIALIZE_COMPLETE:
                dispatch(loadAccountDetail());
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        switch(loadingStage) {
            case LoadingStage.INITIALIZE_FAILURE:
                setShouldRedirect(true);
                break;
            default:
                break;
        }
    }, [loadingStage]);

    if (shouldRedirect) {
        // Redirect to login if not authenticated
        const params = `${_.isEmpty(location.search) ? '?' : ''}${buildReturnPathParam(location.pathname)}`;
        return <Navigate to={redirectPath + params} replace />;
    }

    return <Container>
        {/* Render child routes */}
        <Outlet />
    </Container>;
};

const Container = styled.div`
    height: 100%;
`;

export default ProtectedRoute;
