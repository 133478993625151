import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAgentStatusResponse, IAuth, IJwtData, IRefreshAccessToken } from "@interfaces";
import { RootState } from ".";
import { authApi } from "@api";
import { lsAccount, lsJwt } from "@utilities";
import _ from "lodash";

interface IRefreshTokenPayload {
    refreshToken: string;
    userId: string;
}

export const refreshAccessToken = createAsyncThunk<IRefreshAccessToken, IRefreshTokenPayload>(
    "auth/refreshAccessToken",
    async (payload: IRefreshTokenPayload, { rejectWithValue }) => {
        try {
            const res = await authApi.refresh({
                refresh_token: payload.refreshToken,
                user_id: payload.userId,
            });
            return res.data.data as IRefreshAccessToken;
        } catch (err: any) {
            const errorMessage = "Failed to refresh access token";
            console.error(errorMessage, err);
            return rejectWithValue(errorMessage);
        }
    },
);

export const fetchAgentToken = createAsyncThunk<IAuth, void>(
    "auth/fetchAgentToken",
    async (_, { rejectWithValue }) => {
        try {
            const res = await authApi.agentToken();
            return res.data as IAuth;
        } catch (err: any) {
            if (err.response?.data?.errorCode === 4001) {
                return rejectWithValue({ errorCode: 4001 });
            }
            return rejectWithValue({ error: err.response?.data || "Error fetching agent token" });
        }
    },
);



interface IState {
    auth: IAuth | null;
    jwt: IJwtData | null;
    agentInfo: IAgentStatusResponse | null;
    isLoading: boolean;
    error: string | null;
    tokenInfoAuth: string;
    isAgentTokenInvalid: boolean; // Add specificError to track the specific error state
}

const rawJwt = lsJwt.getItem();
const localJwt: IJwtData | null =
    _.isNil(rawJwt) || _.isEmpty(rawJwt) ? null : (rawJwt as IJwtData);

const initialState: IState = {
    auth: null,
    agentInfo: null,
    jwt: localJwt,
    isLoading: false,
    isAgentTokenInvalid: false, // Flag to track if a specific error occurred
    error: null,
    tokenInfoAuth: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        clearAgentToken: () => {
            return initialState;
        },
        setTokenInfoAuth: (state, action: PayloadAction<string>) => {
            state.tokenInfoAuth = action.payload;
        },
        setAuth: (state, action: PayloadAction<IAuth>) => {
            state.auth = action.payload;
        },
        setAgentInfo: (state, action: PayloadAction<IAgentStatusResponse>) => {
            state.agentInfo = action.payload;
        },
        setJwt: (state, action: PayloadAction<IJwtData>) => {
            state.jwt = action.payload;
            state.isLoading = false;
        },
        removeJwt: (state) => {
            lsJwt.setItem(null);
            lsAccount.setItem(null);
            state.jwt = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(refreshAccessToken.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(refreshAccessToken.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload || "Failed to refresh access token";
            })
            .addCase(
                refreshAccessToken.fulfilled,
                (state, action: PayloadAction<IRefreshAccessToken>) => {
                    state.jwt!.accessToken = action.payload.accessToken;
                    lsJwt.setItem(state.jwt);
                },
            )
            .addCase(fetchAgentToken.fulfilled, (state, action: PayloadAction<IAuth>) => {
                state.auth = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchAgentToken.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAgentToken.rejected, (state, action: PayloadAction<any>) => {
                const { errorCode, error } = action.payload as {
                    errorCode?: number;
                    error?: string;
                };
                if (errorCode === 4001) {
                    state.isAgentTokenInvalid = true;
                    state.error = "Specific error occurred with code 4001";
                } else {
                    state.error = error || "Failed to fetch shared token";
                    state.isAgentTokenInvalid = false;
                }

                state.isLoading = false;
            });
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const { clearAgentToken, setTokenInfoAuth, setAuth, setJwt, removeJwt } =
    authSlice.actions;

export default authSlice.reducer;
