import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StyledDatePicker } from "../StyledComponents";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface IProps {
    id?: string;
    label?: string;
    value: Date | null | undefined;
    onChange(date: Date | null, value?: string | null | undefined): void;
    limitDate?: {
        maxDate: Date | null;
        minDate: Date | null;
    };
    minDateMessage?: string;
    maxDateMessage?: string;
    placeholder?: string;
    name?: string;
    error?: string;
}

export function DatePickerCommon(props: IProps) {
    const {
        id,
        label,
        value,
        error,
        onChange,
        limitDate,
        minDateMessage = "Date should not be before current date",
        maxDateMessage = "Date should not be in the future",
        placeholder = "Select Date",
    } = props;

    const minDate = limitDate?.minDate || new Date();
    const maxDate = limitDate?.maxDate || new Date("2100-01-01");

    let parsedValue: Date | null = null;
    if (value instanceof Date) {
        parsedValue = value;
    } else if (typeof value === "string" || typeof value === "number") {
        const tempDate = new Date(value);
        if (!isNaN(tempDate.getTime())) {
            parsedValue = tempDate;
        }
    }
    // Convert to timestamps for comparison
    const minDateTimestamp = new Date(minDate).setHours(0, 0, 0, 0); // Start of the day
    const maxDateTimestamp = new Date(maxDate).setHours(23, 59, 59, 999); // End of the day
    const valueTimestamp = parsedValue ? parsedValue.getTime() : null;

    const isDateBeforeMin = valueTimestamp !== null && valueTimestamp < minDateTimestamp;
    const isDateAfterMax = valueTimestamp !== null && valueTimestamp > maxDateTimestamp;

    return (
        <StyledDatePicker>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label={label}
                    value={parsedValue || null}
                    onChange={(date) => onChange(date, date ? date.toISOString() : null)}
                    minDate={minDate}
                    maxDate={maxDate}
                    slotProps={{
                        textField: {
                            id: id,
                            placeholder: placeholder,
                            helperText: isDateBeforeMin
                                ? minDateMessage
                                : isDateAfterMax
                                  ? maxDateMessage
                                  : error,
                            error: !!error,
                        },
                    }}
                />
            </LocalizationProvider>
        </StyledDatePicker>
    );
}
