import { DEFAULT_RETURN_PATH, RETURN_TO_PARAM_KEY } from "@constants";
import { IAccount, IAuthLogin, ILogin } from "@interfaces";
import { AppDispatch, initializeAccountDetail, loginV2, setJwt } from "@redux";
import _ from "lodash";
import { Location, NavigateFunction } from "react-router-dom";
import { lsJwt } from "./storage";

/**
 * '/valid/path' // true
 * '/invalid//path' // false
 * 'invalid/path' // false
 * '/another-valid_path/' // true
 * @param path path starting from /
 * @returns True if path is valid else false
 */
const isValidPath = (path: string) => {
    const pathRegex = /^(\/[a-zA-Z0-9-_+]+)+\/?$/;
    return pathRegex.test(path);
};

export const getReturnUrl = (location: Location, ) => {
    const searchParams = new URLSearchParams(location.search);
    const returnToPathFromParam = searchParams.get(RETURN_TO_PARAM_KEY);
    let returnUrl = DEFAULT_RETURN_PATH;
    if (!_.isEmpty(returnToPathFromParam)) {
        const returnTo = `${ returnToPathFromParam?.startsWith('/') ? '' : '/'}${returnToPathFromParam}`;
        if (isValidPath(returnTo)) {
            returnUrl= returnTo;
        }
    }
    return returnUrl;
};

export const buildReturnPathParam = (pathname: string) => `${encodeURIComponent(RETURN_TO_PARAM_KEY)}=${encodeURIComponent(pathname)}`;

export const loginCallback = async (
    dispatch: AppDispatch,
    payload: IAuthLogin,
    navigate: NavigateFunction,
    location: Location,
    onError: (errorMessage: string) => void,
) => {
    if (!payload.data.accessToken) {
        onError("Please check username and password are correct");
    } else {
        lsJwt.setItem(payload.data);
        await dispatch(setJwt(payload.data));
        await dispatch(initializeAccountDetail());
        navigate(getReturnUrl(location));
    }
};

export const handleLogin = async (
    dispatch: AppDispatch,
    data: ILogin,
    navigate: NavigateFunction,
    location: Location,
    onError: (errorMessage: string) => void,
) => {
    const res = await dispatch(loginV2(data)) as { payload: IAuthLogin };
    await loginCallback(dispatch, res.payload, navigate, location, onError);
};

export const checkNeedsRealEmail = (isLoggedIn: boolean, account?: IAccount | null) => {
    if (isLoggedIn && _.isNil(account?.profile_status)) {
        console.warn(`User is logged in but profile status is nil`, account);
    }
    return !isLoggedIn ? false : account?.profile_status === 'require_real_email';
};

export const checkShouldVerifyEmail = (isLoggedIn: boolean, account?: IAccount | null) => {
    if (isLoggedIn && _.isNil(account?.is_email_verified)) {
        console.warn(`User is logged in but is_email_verified flag is nil`, account);
    }
    return !isLoggedIn ? false : account?.is_email_verified === false;
};

export const checkIsProfileSetupInProgress = (isLoggedIn: boolean, account?: IAccount | null) => {
    if (isLoggedIn && _.isNil(account?.profile_status)) {
        console.warn(`User is logged in but profile status is nil`, account);
    }
    return !isLoggedIn ? false : account?.profile_status === 'progress';
};
