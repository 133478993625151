import {
    IForgotPassword,
    ILoginDemo,
    IAgentInfo,
    ILogin,
    ISignUp,
    IGoogleLogin,
    ILoginv2,
    IAppleLogin,
    IAuthenticationCode,
    IForgotPassEmail,
    IRefresh,
    IChangePassword,
    ISetRealEmail,
    IVerifyRealEmail,
} from "@interfaces";
import basicFtApiClient from "./basicFtApiClient";
import wtApiClient from "./wtApiClient";
import ftApiClient from "./ftApiClient";

export const authApi = {
    login: (params: ILogin) => {
        const url = "/agentToken";
        return wtApiClient.post(url, params);
    },
    agentLogin: (params: ILoginv2) => {
        const url = "/agentToken";
        return wtApiClient.post(url, params);
    },
    loginV2: (params: ILogin) => {
        const url = "/user/login/email";
        return ftApiClient.post(url, params);
    },
    refresh: (params: IRefresh) => {
        const url = "/user/token/access";
        return basicFtApiClient.post(url, params);
    },
    signUp: (params: ISignUp) => {
        const url = "/user/signup/email/";
        return ftApiClient.post(url, params);
    },
    googleLogin: (params: IGoogleLogin) => {
        const url = "/user/login/google";
        return ftApiClient.post(url, params);
    },
    appleLogin: (params: IAppleLogin) => {
        const url = "/user/login/apple";
        return ftApiClient.post(url, params);
    },
    loginDemo: (params: ILoginDemo) => {
        const url = "/auth/login";
        return wtApiClient.post(url, params);
    },
    forgotPass: (params: IForgotPassword) => {
        const url = "/user/email/reset-password/";
        return ftApiClient.post(url, params);
    },
    changePass: (params: IChangePassword) => {
        const url = "user/detail/change-password/";
        return ftApiClient.post(url, params);
    },
    forgotPassEmail: (params: IForgotPassEmail) => {
        const url = "/user/login/email/forgot-password/";
        return ftApiClient.post(url, params);
    },
    forgotPassCode: (params: IAuthenticationCode) => {
        const url = "user/email/otp/verify/";
        return ftApiClient.post(url, params);
    },
    setRealEmail: (data: ISetRealEmail) => {
        const url = "/user/apple/new-email/add/";
        return ftApiClient.post(url, data);
    },
    verifyRealEmail: (data: IVerifyRealEmail) => {
        const url = "/user/apple/new-email/verify/";
        return ftApiClient.post(url, data);
    },
    updateAgentInfo: (params: IAgentInfo) => {
        const url = "/updateAgentAccount";
        return wtApiClient.post(url, params);
    },
    agentLoginAdd: (params: ILoginv2) => {
        const url = "/agent/integration/";
        return ftApiClient.post(url, params);
    },
    agentLoginUpdate: (params: ILoginv2) => {
        const url = "/agent/integration/";
        return ftApiClient.put(url, params);
    },
    agentDetail: () => {
        const url = "/agent/integration";
        return ftApiClient.get(url);
    },
    agentToken: () => {
        const url = "/agent/integration/token";
        return ftApiClient.get(url);
    },
    unLinkAgent:()=>{
        const url = "/agent/token/integration/";
        return ftApiClient.delete(url);
    }
};
