import { useParams } from "react-router-dom";
import styled from "styled-components";
import { StyledBookingDetail, BreadcrumbsCommon } from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_BOOKING, PATH_BOOKING_DETAIL, PATH_HOME } from "@routes";
import { selectBooking, useAppSelector } from "@redux";
import { BookingDetailItemModule } from "@module";
import moment from "moment";
import _ from "lodash";
import { breakpoint } from "@utilities";
import { Fragment } from "react";

export const BookingDetail = () => {
    const { id } = useParams<{ id: string }>();

    const breadcrumbsModel = [
        { title: "Home", link: PATH_HOME },
        { title: "Bookings", link: PATH_BOOKING },
        { title: "BookingDetail", link: `${PATH_BOOKING_DETAIL}/${id}` },
    ];

    const copyToClipboard = (bookingReference: string | null) => {
        if (bookingReference) {
            navigator.clipboard.writeText(bookingReference);
        }
    };

    const { listBooking } = useAppSelector(selectBooking);
    const Booking = _.find(listBooking, { bookingReference: id });
    const date = Booking?.purchaseDate ? new Date(Booking.purchaseDate) : null;
    const dateFormat = date ? moment(date).format("DD/MM/YYYY") : "N/A";

    const handleShowMore = () => {
        if (Booking?.customerOrderPageUrl) {
            window.open(Booking.customerOrderPageUrl, "_blank");
        }
    };
    return (
        <AppModuleLayout>
            <StyledBookingDetail isLogin={true}>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsModel} />
                    </div>
                    <ListCarts>
                        <div className="HeadingContainer">
                            <Heading>Booking Details</Heading>
                            <PrintButtonMobile onClick={() => console.log("Print clicked")}>
                                Print
                            </PrintButtonMobile>
                        </div>
                        <GridContainer>
                            <LeftPanel>
                                <div className="SmallText">Created On {dateFormat}</div>
                                <div className="Row">
                                    <span className="Label">Booking Reference</span>
                                    <div className="ReferenceContainer">
                                        <div className="reference">
                                            <span className="Value ">
                                                {Booking?.bookingReference || "N/A"}
                                            </span>
                                            <CopyButton
                                                onClick={() =>
                                                    copyToClipboard(
                                                        Booking?.bookingReference || null,
                                                    )
                                                }
                                            >
                                                Copy
                                            </CopyButton>
                                        </div>
                                    </div>
                                </div>
                                <div className="Row">
                                    <span className="Label">Agent</span>
                                    <span className="Value">
                                        {Booking?.agentUser?.name || "N/A"}
                                    </span>
                                </div>
                                <Separator />
                                {Booking?.products?.map((item, index) => (
                                    <CustomerInfo key={index}>
                                        <CustomerHeading>Customer #1</CustomerHeading>
                                        <CustomerName>
                                            <strong>{item.redeemers[0].name}</strong> (Lead
                                            Customer)
                                        </CustomerName>
                                        <ContactRow>
                                            <Contact>
                                                <img src="/images/mailBlue.svg" alt="mail" />{" "}
                                                {item.redeemers[0].email}
                                            </Contact>
                                            <Contact>
                                                <img src="/images/phone.svg" alt="phone" />{" "}
                                                {item.redeemers[0].phone}
                                            </Contact>
                                        </ContactRow>
                                    </CustomerInfo>
                                ))}

                                <Separator />
                            </LeftPanel>
                            <RightPanel>
                                <PrintButton onClick={handleShowMore}>
                                    Show More
                                </PrintButton>
                                <PricingInfo>
                                    <div className="InfoRow">
                                        <span>Number of items</span>
                                        {Booking?.products?.length || 0}{" "}
                                        {Booking?.products?.length === 1 ? "item" : "items"}
                                    </div>
                                    <div className="InfoRow">
                                        <span>Total RRP</span>${Booking?.paidAmount || "N/A"}
                                    </div>
                                    <Separator />
                                    <div className="InfoRow">
                                        <span>Total Sell Price</span>
                                        <span className="SellPrice">
                                            ${Booking?.netTotal?.toFixed(2) || "N/A"}
                                        </span>
                                    </div>
                                </PricingInfo>
                            </RightPanel>
                        </GridContainer>

                        <DetailWrapper>
                            <ItemsHeader>Items</ItemsHeader>
                            <ItemsList>
                                {Booking?.products?.map((item, index) => (
                                    <Fragment key={index}>
                                        <BookingDetailItemModule data={item} isShowCancel={true} />
                                    </Fragment>
                                ))}
                            </ItemsList>
                        </DetailWrapper>
                    </ListCarts>
                </div>
            </StyledBookingDetail>
        </AppModuleLayout>
    );
};

const ListCarts = styled.div`
    .listCarts {
        background: #f8f9fa;
        border-radius: 8px;
        padding: 20px;
    }
    .HeadingContainer {
        ${breakpoint.breakOnlyMobile`
            display : flex;
            align-items: center;
            justify-content : space-between;
        `}
    }
`;

const Heading = styled.p`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const GridContainer = styled.div`
    display: flex;
    gap: 50px;
    margin-bottom: 50px;
    ${breakpoint.breakOnlyMobile`
        display : flex;
        flex-direction : column;
        gap: 20px;
    `}
`;

const LeftPanel = styled.div`
    width: 800px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    .SmallText {
        color: #6e7375;
        font-size: 12px;
    }
    .Row {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .ReferenceContainer {
            .reference {
                display: flex;
                align-items: center;
                gap: 10px;
                ${breakpoint.breakOnlyMobile`
                    display: flex;
                    align-items: center;
                    gap : 5px;
                    justify-content: space-between;
                `}
            }
            ${breakpoint.breakOnlyMobile`
                width: 100%;
                display : flex;
                gap: 5px;
                flex-direction : column;
             `}
        }
        .Label {
            width: 170px;
        }
        .Value {
            color: #090a18;
        }
    }
    ${breakpoint.breakOnlyMobile`
        width: 100%
     `}
`;

const CopyButton = styled.button`
    padding: 5px 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const Separator = styled.hr`
    border: 1px solid #e6e9eb;
    margin: 20px 0;
`;

const CustomerInfo = styled.div`
    margin-top: 20px;
    display: grid;
    gap: 10px;
`;

const CustomerHeading = styled.div`
    font-weight: bold;
    font-size: 14px;
    color: #6e7375;
`;

const CustomerName = styled.div`
    color: #131214;
`;

const ContactRow = styled.div`
    display: flex;
    gap: 30px;
    ${breakpoint.breakOnlyMobile`
       flex-direction : column;
       gap: 5px;
    `}
`;

const Contact = styled.span`
    display: flex;
    align-items: center;
`;

const RightPanel = styled.div`
    display: flex;
    width: 40%;
    flex-direction: column;
    ${breakpoint.breakOnlyMobile`
       width: 100%
    `}
`;

const PrintButton = styled.button`
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 20px;
    display: block;
    ${breakpoint.breakOnlyMobile`
        display: none;
    `}
`;

const PrintButtonMobile = styled.button`
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 20px;
    display: none;
    ${breakpoint.breakOnlyMobile`
        display : block;
    `}
`;

const PricingInfo = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #090a18;

    .InfoRow {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .SellPrice {
            background-color: #f2f2f2;
            padding: 12px;
            color: #31b4b9;
            font-weight: bold;
            font-size: 20px;
        }
    }
`;

const DetailWrapper = styled.div`
    margin-top: 50px;
`;

const ItemsHeader = styled.div`
    background-color: #090a18;
    color: #fff;
    height: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
`;

const ItemsList = styled.div`
    .listFare {
        margin-top: 20px;
    }
`;
