import { useState } from "react";
import { StyledButton } from "../StyledComponents";
import AppleSignin from "react-apple-signin-auth";
import { useDispatch } from "react-redux";
import { AppDispatch, appleLoginAction, toggleLoading } from "@redux";
import { IAppleLogin, IAuthLogin } from "@interfaces";
import { ErrorToast } from "../toasts/ErrorToast";
import { APPLE_OAUTH_CLIENT_ID } from "@constants";
import { loginCallback } from "src/Utilities/routeHelper";
import { useLocation, useNavigate } from "react-router-dom";
const BaseUrl = process.env.REACT_APP_REDIRECT_URL;

const CustomAppleSignIn = () => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    const handleAppleSignInSuccess = (response: any) => {
        setIsSubmitting(true);
        dispatch(toggleLoading(true));

        const appleTokenResponse = response.authorization.id_token;
        if (appleTokenResponse) {
            const values: IAppleLogin = { access_token: appleTokenResponse };
            dispatch(appleLoginAction(values))
                .then(async (loginSuccess) => {
                    await loginCallback(
                        dispatch,
                        loginSuccess.payload as IAuthLogin,
                        navigate,
                        location,
                        (errorMessage: string) => {
                            console.error(errorMessage);
                            ErrorToast({ Message: `Apple sign in failed` });
                            setIsSubmitting(false);
                            dispatch(toggleLoading(false));
                        }
                    );
                    setIsSubmitting(false);
                    dispatch(toggleLoading(false));
                })
                .catch((error: any) => {
                    ErrorToast({ Message: `${error}` });
                    setIsSubmitting(false);
                    dispatch(toggleLoading(false));
                });
        }
    };

    const handleAppleSignInError = (error: any) => {
        if (error.error === "popup_closed_by_user") {
            console.warn("Apple sign-in was canceled by the user.");
            ErrorToast({ Message: "Sign-in canceled. Please try again if you wish to log in." });
        } else {
            console.error("Apple sign-in error:", error);
            ErrorToast({ Message: "Apple sign-in failed. Please try again." });
        }
        setIsSubmitting(false);
        dispatch(toggleLoading(false));
    };

    return (
        <AppleSignin
            authOptions={{
                clientId: APPLE_OAUTH_CLIENT_ID,
                scope: "email name",
                redirectURI: `${BaseUrl}`,
                usePopup: true,
            }}
            onSuccess={handleAppleSignInSuccess}
            onError={handleAppleSignInError}
            uiType="dark"
            render={(props: any) => (
                <StyledButton
                    disabled={isSubmitting}
                    onClick={props.onClick}
                    borderRadius="4px"
                    type="button"
                    color="#131214"
                >
                    <img src="/images/Apple.svg" alt="Apple Logo" />
                    Log In with Apple
                </StyledButton>
            )}
        />
    );
};

export default CustomAppleSignIn;
