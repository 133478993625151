import { IAuthLogin, IGoogleLogin } from "@interfaces";
import { useGoogleLogin } from "@react-oauth/google";
import { AppDispatch, googleLoginAction, toggleLoading } from "@redux";
import { useDispatch } from "react-redux";
import { ErrorToast } from "../toasts/ErrorToast";
import { StyledButton } from "@components";
import { useState } from "react";
import { loginCallback } from "src/Utilities/routeHelper";
import { useLocation, useNavigate } from "react-router-dom";

const GoogleLoginButton = () => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const navigate = useNavigate();

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setIsSubmitting(true);
            dispatch(toggleLoading(true));
            try {
                const googleToken = tokenResponse.access_token;
                const values: IGoogleLogin = { google_access_token: googleToken };
                const res = (await dispatch(googleLoginAction(values))) as {
                    payload: IAuthLogin;
                };
                await loginCallback(
                    dispatch,
                    res.payload,
                    navigate,
                    location,
                    (errorMessage) => {
                        console.error(errorMessage);
                        ErrorToast({ Message: 'Google login failed' });
                    }
                );
            } catch (error) {
                ErrorToast({ Message: `${error}` });
            } finally {
                setIsSubmitting(false);
                dispatch(toggleLoading(false));
            }
        },
        onError: (errorResponse) => {
            ErrorToast({ Message: `Google login error:${errorResponse}` });
            setIsSubmitting(false);
            dispatch(toggleLoading(false));
        },
    });

    return (
        <StyledButton
            borderRadius="4px"
            onClick={() => handleGoogleLogin()}
            disabled={isSubmitting}
            textColor="131214"
            type="button"
            color="#F4F6F7"
        >
            <img src="/images/Google.svg" alt="Google Logo" />
            Log In with Google
        </StyledButton>
    );
};

export default GoogleLoginButton;
