import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconSetupLoader } from "../Svg";
import { useEffect, useState } from "react";
import { bookingApi } from "@api";
import { PATH_BOOKING } from "@routes";
import { ErrorToast } from "../toasts/ErrorToast";
import { AppModuleLayout } from "@layouts";
import { SuccessToast } from "../toasts/SuccessToast";

export const SetupOrderCheckComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const completeBookingFailedMessage = `Booking check failed`;
        const completeBookingFailedParam = '?status=failed';
        const processOrder = async () => {
            try {
                const response = await bookingApi.completeOrder(location.search);
                if (response.status === 200) {
                    SuccessToast({ Message: 'Booking completed'});
                } else {
                    ErrorToast({ Message: completeBookingFailedMessage });
                }
                navigate(PATH_BOOKING + (response.status !== 200 ? completeBookingFailedParam : ''));
            } catch (error) {
                console.error(error);
                ErrorToast({ Message: completeBookingFailedMessage });
                navigate(PATH_BOOKING + completeBookingFailedParam);
            } finally {
                setIsLoading(false);
            }
        };
        processOrder();
    }, [location]);

    return (
        <AppModuleLayout>
            <>
                {isLoading && (
                    <StyledOuterWrapper>
                        <StyledInnerWrapper>
                            <StyledLogoWrapper>
                                <img
                                    src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg"
                                    alt="logo"
                                    className="logoItem2 logoColor"
                                />
                            </StyledLogoWrapper>
                            <StyledCenteredContainer>
                                <IconSetupLoader />
                                <div>Verifying payment ...</div>
                            </StyledCenteredContainer>
                        </StyledInnerWrapper>
                    </StyledOuterWrapper>
                )}
            </>
        </AppModuleLayout>
    );
};

const StyledOuterWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 80px 16px;
`;

const StyledInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 540px;
    background-color: #ffffff;
    padding: 64px 10px;
    border-radius: 4px;
`;

const StyledLogoWrapper = styled.div`
    height: 35px;

    .logoItem2 {
        width: 260px;

        @media (min-width: 768px) {
            width: 300px;
        }

        @media (min-width: 1024px) {
            width: 350px;
        }
    }
`;

const StyledCenteredContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 12px;
`;
