import styled from "styled-components";
import { memo } from "react";
import moment from "moment";

import { IBookingDetail, IListBookings } from "@interfaces";
import { breakpoint } from "@utilities";

interface IProps {
    bookingInfo: IBookingDetail;
    statusApp: boolean;
    bookingPriceDetail?: IListBookings;
}

export const BookingDetailGeneralComponent = memo((props: IProps) => {
    //prop
    const { bookingInfo, statusApp, bookingPriceDetail } = props;
    //page variable
    const date = new Date(bookingInfo?.purchaseDate);
    const dateFormat = moment(date).format("DD MMM YYYY");
    const commission = bookingPriceDetail ? bookingPriceDetail.commissionAmount : 0;
    const commissionPercent = bookingPriceDetail ? commission / bookingPriceDetail.totalAmount : 0;

    const leadCustomerName =
        bookingInfo?.products[0]?.redeemers[0]?.name ||
        `${bookingPriceDetail?.firstName} ${bookingPriceDetail?.lastName}`;
    const leadCustomerEmail =
        bookingInfo?.products[0]?.redeemers[0]?.email || bookingPriceDetail?.email;
    const leadCustomerPhone =
        bookingInfo?.products[0]?.redeemers[0]?.phone || bookingPriceDetail?.phone;

    if (!bookingInfo) {
        return <></>;
    }

    const totalItem = bookingInfo.products.reduce((total, product) => {
        return total + +product.qty;
    }, 0);

    return (
        <Container>
            <div className="generalInfo">
                <img src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" alt="" />
                <p className="userName">Booking Reference</p>
                <p className="bookingId">{bookingInfo.bookingReference}</p>
                <p className="bookingDate">Date of Purchase: {dateFormat}</p>
            </div>
            <div className="userInfoNPrice">
                <div className="userInfo">
                    <p className="subHeading">Lead customer</p>
                    {/* Mặc định thăng redeemers đầu tiền data trả về là Lead customer> */}
                    <p className="email">{leadCustomerName}</p>
                    <p className="email">{leadCustomerEmail}</p>
                    <p className="email">{leadCustomerPhone}</p>
                    {/* Nhưng thằng redeemers sau là của Additional customer */}
                    {bookingInfo?.products[0]?.redeemers?.length > 1 && (
                        <>
                            <p style={{ marginTop: "5px" }} className="subHeading">
                                Additional customer
                            </p>

                            {bookingInfo?.products[0]?.redeemers?.map((customer, index) => {
                                if (index === 0) return <></>; // bỏ thằng đàu vì nó là lead customer
                                return (
                                    <p className="additionalCustomer" key={index}>
                                        {customer.name}
                                    </p>
                                );
                            })}
                        </>
                    )}
                </div>
                <div className="totalPrice">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="field">Number of Items</div>
                                </td>
                                <td></td>
                                {/* <td><div className="value">{bookingInfo.numberOfItem} Items</div></td> */}
                                <td>
                                    <div className="value">{totalItem} Items</div>
                                </td>
                            </tr>
                            {statusApp ? (
                                <>
                                    <tr>
                                        <td>
                                            <div className="field">Commission</div>
                                        </td>
                                        {/* <td><div className="commissionPrice">{bookingInfo.commissionPercent}%</div></td> */}
                                        {/* <td><div className="value commissionPrice">${bookingInfo.commissionPrice}</div></td> */}
                                        <td>
                                            <div className="commissionPrice">
                                                {(commissionPercent * 100).toFixed(2)}%
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className="value commissionPrice">
                                                ${commission.toFixed(2)}
                                            </div>
                                        </td> */}
                                    </tr>
                                </>
                            ) : null}
                        </tbody>
                    </table>
                    <div className="total">
                        <span className="text">Total Sell Price</span>
                        {/* <span className="value">${Number(bookingInfo.totalPrice).toFixed(2)}</span> */}
                        <span className="value">
                            ${(bookingPriceDetail?.totalAmountExcludeCCFee || 0).toFixed(2)}
                        </span>
                    </div>
                </div>
            </div>
        </Container>
    );
});

const Container = styled.div`
    .generalInfo {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 28px 0;

        img {
            margin-bottom: 27px;
            width: 300px;
        }

        .userName {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            color: ${(props) => props.theme.colors.black};
            margin-bottom: 2px;

            ${breakpoint.breakLarge`
                font-size: 24px;
                line-height: 31px;
                margin-bottom: 14px;
            `}
        }

        .bookingId {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            color: ${(props) => props.theme.colors.main};
            margin-bottom: 2px;

            ${breakpoint.breakLarge`
                font-size: 36px;
                line-height: 47px;
                margin-bottom: 11px;
            `}
        }

        .bookingDate {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: ${(props) => props.theme.colors.gray_2};
        }
    }

    .userInfo {
        margin-top: 16px;
        background-color: #fff;
        padding: 18px 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: ${(props) => props.theme.colors.gray_1};
        text-align: left;

        ${breakpoint.breakTablet`
            flex: 1;
            padding: 15px 0 7px 0;
            font-size: 12px;
            line-height: 20px;
        `}

        ${breakpoint.breakLarge`
            padding: 21px 0 21px 0;
            font-size: 16px;
            line-height: 21px;
        `}

        .subHeading {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            color: ${(props) => props.theme.colors.black};
            margin-bottom: 4px;
            padding-top: 4px;

            ${breakpoint.breakTablet`
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 0px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
                font-weight: 700;
                line-height: 21px;
                margin-bottom: 4px;
            `}
        }

        .email {
            margin-bottom: 4px;

            ${breakpoint.breakTablet`
                margin-bottom: 0;
            `}

            ${breakpoint.breakLarge`
                margin-bottom: 4px;
            `}
        }

        .phoneNumber {
            margin-bottom: 12px;

            ${breakpoint.breakTablet`
                margin-bottom: 3px;
            `}

            ${breakpoint.breakLarge`
                margin-bottom: 17px;
            `}
        }

        & > p {
            ${breakpoint.breakTablet`
                padding-left: 17px;
            `}
        }
    }

    .totalPrice {
        padding: 15px 0 15px 0;
        background-color: #fff;
        margin-top: 16px;
        flex: 1;

        ${breakpoint.breakTablet`
            margin-left: 18px;
        `}

        ${breakpoint.breakIpadPro`
            margin-left: 18px;
        `}

        ${breakpoint.breakLarge`
            padding: 21px 0 0 0;
        `}

        table {
            padding: 0 17px;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 19px;
            color: ${(props) => props.theme.colors.gray_1};

            ${breakpoint.breakTablet`
                font-size: 13px;
                line-height: 13px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
                line-height: 26px;
            `}
        }

        tr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 17px;

            .commissionPrice {
                color: #f43f3f;
            }
        }

        .total {
            padding: 14px 17px 0px 17px;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            color: ${(props) => props.theme.colors.black};
            display: flex;
            justify-content: space-between;
            border-top: 1px solid ${(props) => props.theme.colors.gray_5};

            ${breakpoint.breakTablet`
                font-size: 14px;
                line-height: 15px;
            `}

            ${breakpoint.breakLarge`
                padding: 16px 17px 16px 17px;
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;
            `}

            .value {
                font-weight: 700;
                color: ${(props) => props.theme.colors.main};
            }
        }
    }
`;
