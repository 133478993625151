import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import React, { useState } from "react";

import { IForgotPassEmail, IHandleSubmit } from "@interfaces";
import { InputCommon, StyledBorderButton, StyledButton, StyledForm, StyledLink } from "@components";
import { IHandleChange, IHandleBlur } from "@interfaces";
import { PATH_LOGIN } from "@routes";
import { NavLink } from "react-router-dom";
import { theme, useLogoutHandler } from "@utilities";
import { selectAccount, useAppSelector, useIsLoggedIn } from "@redux";
import { checkNeedsRealEmail } from "src/Utilities/routeHelper";

interface SendEmailFormProps {
    values: IForgotPassEmail;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
    constantText: string;
    constantContact: string;
}

export const SendEmailComponent = (props: SendEmailFormProps) => {
    const { values, handleChange, handleBlur, handleSubmit, constantText, constantContact } = props;
    const navigate = useNavigate();
    const isLoggedIn = useIsLoggedIn();
    const account = useAppSelector(selectAccount);
    const [needsRealEmail] = useState(checkNeedsRealEmail(isLoggedIn, account.account));
    const headingText = needsRealEmail ? 'Set Your Email' : 'Lost Password Recovery';
    const subHeadingText = needsRealEmail
        ? 'Please enter your real email address to proceed.'
        : 'Please enter your registered email address to reset your password.';
    const handleLogout = useLogoutHandler();
    const handleBackToLogin = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (isLoggedIn) {
            await handleLogout();
            return;
        }
        navigate(PATH_LOGIN);
    };
    return (
        <>
            <StyledWrapper onSubmit={handleSubmit}>
                <h2 className="heading">{headingText}</h2>
                <p className="subHeading">{subHeadingText}</p>
                <InputCommon
                    name="email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.email}
                    label="Enter Your email address"
                    type="text"
                    img="/images/mail.svg"
                />
                <StyledButton
                    borderRadius="4px"
                    color={["#436CB2 0%", "#28B7C3 100%"]}
                    className="send_link"
                    type="submit"
                >
                    Continue
                </StyledButton>
                <StyledBorderButton
                    borderRadius="4px"
                    textColor={theme.colors.main}
                    color="transparent"
                    borderColor={theme.colors.main}
                    onClick={handleBackToLogin}
                >
                    {isLoggedIn ? 'Log out' : 'Back to Login'}
                </StyledBorderButton>
                {
                    !isLoggedIn && <StyledLink className="extraMargin">
                        Don't have an account? <NavLink to="/signup"> Sign up</NavLink>, to start
                        selling today.
                    </StyledLink>
                }
                <StyledLine className="contact">{constantContact}</StyledLine>
                <StyledLine>{constantText}</StyledLine>
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled(StyledForm)`
    .heading {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 18px;
    }

    .subHeading {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: ${(props) => props.theme.colors.gray_2};
    }
    .send_link {
        margin-top: 30px;
    }
    .extraMargin {
        margin: 20px 0px;
    }
`;

const StyledLine = styled.div`
    font-weight: 100;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray_1};
    margin: 10px 0px;
`;
